import { useEffect, useState } from "react";
import Modal from "react-modal"
import { useHeader } from "../../context/headerContext";
import { ReactComponent as UserAvatar } from "../../assets/svg/icons/user-avatar.svg";
import { ReactComponent as EmptyCart } from "../../assets/svg/icons/empty-cart.svg";
import { getDateTime } from "../../utils";
import CancelModal from "../../assets/img/cancel-modal.png";
import { useNetworks } from "../../context/networkContext";

export const getServiceColor = (service) => {
  if (service?.toLowerCase().includes('api')) {
    return 'bg-[#FFECDA] text-[#E28327]';
  } else if (service?.toLowerCase().includes('validator')) {
    return 'bg-[#E6F6F3] text-[#538989]';
  } else if (service?.toLowerCase().includes('dedicated')) {
    return 'bg-[#EDEEFF] text-[#6365D6]';
  } else if (service?.toLowerCase().includes('data')) {
    return 'bg-[#F8F8F9] text-black';
  } else {
    return 'bg-[#E9F1FF] text-[#045CF4]';
  }
}

const OtherService = ({ service, header }) => {
  const [projectService, setProjectService] = useState(service?.projectType);
  
  useEffect(() => {
    setProjectService(service?.projectType);
  }, [service]);
  return (
    <div>
    { 
      <div className={`flex items-center text-[12px] text-[#636363] ${!header && 'p-2 border-[1px] border-[#E3E3E3] rounded-[100px] mt-3 text-black'}`}>
        <div className="w-[50%] pr-7">
          {
          header ? 'Service' :
          <div className={`h-[35px] w-full flex items-center justify-center rounded-[30px] ${projectService && getServiceColor(projectService)}`}>
            {projectService}
          </div>}
        </div>
        <div className="w-[25%]">
          {header ? 'Time' : getDateTime(service?.createdAt).time}
        </div>
        <div className="w-[25%]">
          {header ? 'Date' : getDateTime(service?.createdAt).date}
        </div>
      </div>
    }
    </div>
  )
}

const UserInsight = ({ modalIsOpen, openModal, closeModal, user, firstService}) => {
  const { minimizeNav } = useHeader();
  const { networks } = useNetworks();

  // Styles for modal
  const customStyles = {
    overlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      opacity: '1',
      background: '#00000051',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
    },
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="View user Modal"
        overlayClassName={`
          left-0 bg-[#00000051]
          ${minimizeNav ? 'xl:left-[70px]' : 'xl:left-[270px]'}`
        }
        className="absolute bg-white"
        parentSelector={() => document.querySelector('.block-app')}
      >
        <div className={`user-insight ${minimizeNav ? 'xl:w-[calc(90vw-70px)]' : 'xl:w-[calc(90vw-220px)]'}`}>
          <div className="user-insight__info">
            <button onClick={() => closeModal()} className="absolute right-7 top-7">
              <img src={CancelModal} alt="" width={22} />
            </button>
            <div className="flex flex-col items-center self-center gap-1">
              <UserAvatar className="scale" width={150} height={150} />
              <h2 className="text-[20px] font-[500] text-black text-center mt-1">{`${user?.firstName} ${user?.lastName}`}</h2>
              <div className={`h-[40px] w-full max-w-[200px] flex items-center justify-center rounded-[30px] mb-2 ${firstService && getServiceColor(firstService)}`}>
                {firstService}
              </div>
            </div>
            <p>User ID:&nbsp;&nbsp;&nbsp;{user?.id}</p>
            <p>Time Registered:&nbsp;&nbsp;&nbsp;{getDateTime(user?.createdAt).time}</p>
            <p>Date Registered:&nbsp;&nbsp;&nbsp;{getDateTime(user?.createdAt).date}</p>
            <div>Networks Deployed:&nbsp;&nbsp;&nbsp;{
                networks && user?.services.rows && user?.services.rows.map((svc, index) => {
                  const serviceNetwork = networks.filter((network) => {
                    return svc.networkId === network.id;
                  });
                  if (serviceNetwork.length > 0) {
                    if (index !== user?.services.rows.length - 1) {
                      return `${serviceNetwork[0]?.name}, `
                    }
                    return serviceNetwork[0]?.name;
                  }
                  return;
                })
              }
              {user?.services?.rows?.length === 0 && '---'}
            </div>
          </div>
          <div className="user-insight__services">
            <h2 className="text-[20px] font-[500] text-black">Other services in use</h2>
            <div className="mt-5 h-full">
              {user?.services.rows?.length > 0 && <OtherService header />}
              {
                user?.services.rows?.length > 0 ?
                user?.services.rows.map((svc, index) => {
                  return (
                    <div key={index}>
                      <OtherService service={svc} />
                    </div>
                  )
                })
                :
                <div className="flex flex-col items-center w-full mt-10">
                  <EmptyCart height={200} />
                  <h2 className="text-[20px] font-[600] text-black">Nothing here...</h2>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserInsight;
