import AWS from "./assets/img/aws.png";
import GCP from "./assets/img/gcp.png";

export const appConfig = {
  networks: [
    {
      logo: "https://cryptologos.cc/logos/polkadot-new-dot-logo.svg?v=023",
      id: 1,
      name: "Polkadot",
      description:
        "Using a sharded architecture, Polkadot can process transactions and data in parallel.",
      parameters: {
        v1: {
          amis: [
            {
              slug: "blockops-v0.1.0",
              label: "blockops-v0.1.0",
            },
          ],
          zones: [
            {
              slug: "Asia-East1",
              label: "Taiwan",
            },
            {
              slug: "Asia-East2",
              label: "Hong Kong",
            },
            {
              slug: "Asia-Southeast2",
              label: "Singapore",
            },
            {
              slug: "Europe-North2",
              label: "Finland",
            },
            {
              slug: "Europe-West2",
              label: "London",
            },
            {
              slug: "Northamerica-Northeast2",
              label: "Ontario",
            },
            {
              slug: "Us-East4",
              label: "Virginia",
            },
          ],
          nodeTypes: [
            {
              slug: "archival",
              label: "Collator (Archival) Node",
            },
          ],
          chainTypes: [
            {
              slug: "relay",
              label: "Relaychain",
            },
            {
              slug: "para",
              label: "Parachain",
            },
          ],
          imageVersions: [
            {
              slug: "polkadot-v0.9.31",
              label: "polkadot-v0.9.31",
            },
          ],
          cloudProviders: [
            {
              slug: "aws",
              label: "Amazon Web Service",
              icon: AWS,
            },
            {
              slug: "gcp",
              label: "Google Cloud Service",
              icon: GCP,
            },
            /* {
              slug: "maz",
              label: "Microsoft Azure",
            }, */
          ],
        },
        rpc: {
          https: "https://polkadot-rpc.blockops.network/rpc",
          websocket: "wss://polkadot-rpc.blockops.network/ws",
        },
      },
      specifications: {
        v1: {
          cpu: {
            max: 8,
            min: 1,
            unit: "core",
            default: 4,
            available: [2, 4, 8],
          },
          ref: "https://wiki.polkadot.network/docs/maintain-guides-how-to-validate-polkadot#reference-hardware",
          memory: {
            max: 64000000000,
            min: 1000000000,
            unit: "byte",
            default: 16000000000,
          },
          storage: {
            max: 5000000000000,
            min: 50000000000,
            unit: "byte",
            default: 1000000000000,
          },
        },
      },
      isActive: 1,
      createdAt: "2023-04-07T13:58:32.000Z",
      updatedAt: "2023-04-07T13:58:32.000Z",
    },
    {
      logo: "https://cryptologos.cc/logos/kusama-ksm-logo.svg?v=023",
      id: 2,
      name: "Kusama",
      description:
        "A scalable and interoperable network for developers to build decentralized applications (dApps).",
      parameters: {
        v1: {
          amis: [
            {
              slug: "blockops-v0.1.0",
              label: "blockops-v0.1.0",
            },
          ],
          zones: [
            {
              slug: "Asia-East1",
              label: "Taiwan",
            },
            {
              slug: "Asia-East2",
              label: "Hong Kong",
            },
            {
              slug: "Asia-Southeast2",
              label: "Singapore",
            },
            {
              slug: "Europe-North2",
              label: "Finland",
            },
            {
              slug: "Europe-West2",
              label: "London",
            },
            {
              slug: "Northamerica-Northeast2",
              label: "Ontario",
            },
            {
              slug: "Us-East4",
              label: "Virginia",
            },
          ],
          nodeTypes: [
            {
              slug: "collator",
              label: "Collator Node",
            },
          ],
          chainTypes: [
            {
              slug: "relay",
              label: "Relaychain",
            },
            {
              slug: "para",
              label: "Parachain",
            },
          ],
          imageVersions: [
            {
              slug: "Polkadot-v0.9.31",
              label: "Polkadot-v0.9.31",
            },
          ],
          cloudProviders: [
            {
              slug: "aws",
              label: "Amazon Web Service",
              icon: AWS,
            },
            {
              slug: "gcp",
              label: "Google Cloud Service",
              icon: GCP,
            },
            /* {
              slug: "maz",
              label: "Microsoft Azure",
            }, */
          ],
        },
        rpc: {
          https: "https://kusama-rpc.blockops.network/rpc",
          websocket: "wss://kusama-rpc.blockops.network/ws",
        },
      },
      specifications: {
        v1: {
          cpu: {
            max: 8,
            min: 1,
            unit: "core",
            default: 4,
          },
          ref: "https://wiki.kusama.network/docs/maintain-guides-how-to-validate-kusam#reference-hardware",
          memory: {
            max: 64000000000,
            min: 1000000000,
            unit: "byte",
            default: 16000000000,
          },
          storage: {
            max: 5000000000000,
            min: 50000000000,
            unit: "byte",
            default: 1000000000000,
          },
        },
      },
      isActive: 1,
      createdAt: "2023-04-07T13:58:32.000Z",
      updatedAt: "2023-04-07T13:58:32.000Z",
    },
  ],
  cloudProviders: [
    {
      slug: "aws",
      label: "Amazon Web Service",
      icon: AWS,
    },
    {
      slug: "gcp",
      label: "Google Cloud Service",
      icon: GCP,
    },
    {
      slug: "maz",
      label: "Microsoft Azure",
    },
  ],
};
