import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/layouts/page-layout/PageLayout";
import { getUsers } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { NETWORK_STATE, getCurrentWeek, getPreviousWeek } from "../../utils";
import { ReactComponent as UpTrend } from "../../assets/svg/icons/trending_up.svg";
import { ReactComponent as DownTrend } from "../../assets/svg/icons/trending_down.svg";
import { usePage } from "../../context/pageContext";

const Users = () => {
  const [users, setUsers] = useState({ active: [], terminated: [] });
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [networkStatus, setNetworkStatus] = useState({
    active: NETWORK_STATE.LOADING,
    terminated: NETWORK_STATE.LOADING,
    all: NETWORK_STATE.LOADING,
  });
  const [analytics, setAnalytics] = useState({
    cardTwo: {},
    cardOne: {},
    itemsLength: { active: 0, terminated: 0 },
  });
  const [allFilters, setAllFilters] = useState({
    active: {
      searchString: "",
      date: null,
      services: [],
    },
  });
  const navigate = useNavigate();
  const auth = useAuth();
  const loadRef = useRef(1);

  // Function to get all api active users
  const fetchUsers = async (state) => {
    let activeState = {
      projects: users.active,
      filteredUsers: filteredUsers.active,
      networkStatus: networkStatus.active,
    };
    if (state === "all" || state === "active") {
      try {
        setNetworkStatus({
          ...networkStatus,
          active: NETWORK_STATE.LOADING,
        });
        const response = await getUsers();
        const allUsers = response?.data?.data;
        const sortedUsers = allUsers.sort((u1, u2) => {
          return u2.createdAt - u1.createdAt;
        });
        setUsers({
          ...users,
          active: sortedUsers,
        });
        setFilteredUsers({
          ...filteredUsers,
          active: sortedUsers,
        });
        setNetworkStatus({
          ...networkStatus,
          active: NETWORK_STATE.SUCCESS,
          terminated: NETWORK_STATE.SUCCESS,
        });
        activeState = {
          projects: sortedUsers,
          filteredUsers: sortedUsers,
          networkStatus: NETWORK_STATE.SUCCESS,
        };
      } catch (err) {
        err?.response?.status === 401 && navigate("/login");
        if (err.response?.status === 404) {
          activeState.networkStatus = NETWORK_STATE.SUCCESS;
          setNetworkStatus({
            ...networkStatus,
            active: NETWORK_STATE.SUCCESS,
            terminated: NETWORK_STATE.SUCCESS,
            all: NETWORK_STATE.SUCCESS,
          });
          return;
        } else {
          activeState.networkStatus = NETWORK_STATE.ERROR;
          setNetworkStatus({
            ...networkStatus,
            active: NETWORK_STATE.ERROR,
          });
        }
        console.log(err);
      }
    }
  };

  // Function to calculate analytics
  const getAnalytics = () => {
    // Get the total signups last week and this week
    const lastSunday = getPreviousWeek(1)?.getTime();
    const thisSunday = getCurrentWeek(0)?.getTime();
    const nextSunday = getCurrentWeek(7)?.getTime();

    const lastWeekSignups = users?.active?.filter((user) => {
      const dateCreated = new Date(user?.createdAt).getTime();
      return dateCreated >= lastSunday && dateCreated < thisSunday;
    });
    const thisWeekSignups = users?.active?.filter((user) => {
      const dateCreated = new Date(user?.createdAt).getTime();
      return dateCreated >= thisSunday && dateCreated < nextSunday;
    });
    const deployedDiff = thisWeekSignups.length - lastWeekSignups.length;
    setAnalytics({
      ...analytics,
      cardOne: {
        icon: null,
        title: "Total signups",
        smallText: {
          icon:
            deployedDiff > 0 ? (
              <UpTrend />
            ) : deployedDiff === 0 ? (
              "neutral"
            ) : (
              <DownTrend />
            ),
          iconBg:
            deployedDiff > 0
              ? "bg-[#E3F8EE]"
              : deployedDiff === 0
              ? "bg-[#F8F8F9]"
              : "bg-[#FEF0F0]",
          iconText: `${deployedDiff > 0 ? "+" : ""}${
            deployedDiff !== 0 ? deployedDiff : ""
          }`,
          text:
            deployedDiff > 0
              ? "More than last week"
              : deployedDiff === 0
              ? "Same as last week"
              : "Less than last week",
        },
        count: `${thisWeekSignups.length > 0 ? "+" : ""}${
          thisWeekSignups.length
        }`,
      },
      cardTwo: {
        icon: null,
        title: "Cancelled signups",
        smallText: {
          icon: "neutral",
          iconBg: "bg-[#F8F8F9]",
          iconText: "",
          text: "No data avalaible.",
        },
        count: 0,
      },
      itemsLength: {
        active: users.active?.length,
        terminated: 0,
      },
    });
  };

  // Function to Filter projects based on user name or user id
  const filterByNameAndId = (searchString, users) => {
    if (searchString.length > 0) {
      const u = users.filter((user) => {
        const username = `${user.firstName} ${user.lastName}`;
        return (
          username.toLowerCase().includes(searchString) ||
          user.id.toString().includes(searchString)
        );
      });
      return u;
    } else {
      return users;
    }
  };

  // Function to Filter users by date created
  const filterByDate = (timeSpan, users) => {
    if (timeSpan) {
      const todaysDate = new Date();
      const lastXDays = new Date().setDate(todaysDate.getDate() - +timeSpan);
      const usersByDate = users.filter((user) => {
        return new Date(user?.createdAt).getTime() >= lastXDays;
      });
      return usersByDate;
    } else {
      return users;
    }
  };

  // Function to Filter users by services
  const filterByService = (filters, users) => {
    const serviceMapping = {
      1: "api",
      2: "validator",
      3: "dedicated",
      4: "data",
    };

    if (filters.length > 0) {
      const usersByService = users?.filter((user) => {
        const services = user?.services?.rows;
        if (filters.includes("1") && services) {
          const serviceExists = services?.filter((svc) => {
            return svc?.projectType?.toLowerCase().includes(serviceMapping[1]);
          });
          if (serviceExists?.length > 0) {
            return user;
          }
        }
      });
      return usersByService;
    } else {
      return users;
    }
  };

  // Filter projects when a filter is added to activeUsers
  useEffect(() => {
    if (users?.active) {
      let filterResults = [...users.active];
      if (allFilters.active.date) {
        filterResults = filterByDate(allFilters.active.date, filterResults);
      }
      if (allFilters.active.services?.length > 0) {
        filterResults = filterByService(
          allFilters.active.services,
          filterResults
        );
      }
      if (allFilters.active.searchString?.length > 0) {
        filterResults = filterByNameAndId(
          allFilters.active.searchString,
          filterResults
        );
      }
      const sortedResults = filterResults.sort((u1, u2) => {
        return u2.createdAt - u1.createdAt;
      });
      setFilteredUsers({ ...filteredUsers, active: sortedResults });
    }
  }, [
    allFilters.active.searchString,
    allFilters.active.date,
    allFilters.active.services.length,
  ]);

  useEffect(() => {
    if (loadRef.current === 1) {
      auth.setHeaderText(["Users"]);
      fetchUsers("all");
    }
    loadRef.current = 2;
  }, []);

  useEffect(() => {
    getAnalytics();
  }, [users]);

  /* useEffect(() => {
    if (apiPage.reload) {
      fetchUsers("all");
    }
  }, [apiPage]); */

  return (
    <PageLayout
      pageType={"user"}
      filteredItems={filteredUsers}
      allFilters={allFilters}
      setAllFilters={setAllFilters}
      networkStatus={networkStatus}
      analytics={analytics}
      fetchItems={fetchUsers}
    />
  );
};

export default Users;
