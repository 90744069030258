import { toast } from "react-hot-toast";

export const NETWORK_STATE = {
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  IDLE: "IDLE",
  ERROR: "ERROR",
};

export const alertSuccess = (message) => {
  toast.success(message);
};

export const alertError = (message) => {
  toast.error(message);
};

export const containsSpecialChars = (password) => {
  const specialChars = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  return specialChars.test(password);
};

export const convertToGigaByte = (value, unit = "byte") => {
  if (!value) return 0;

  switch (unit) {
    case "byte":
      return Math.ceil(value / 1e9);
    case "kilobyte":
      return Math.ceil(value / 1e6);
    default:
      return Math.ceil(value);
  }
};

export const getDateTime = (dateString) => {
  // Get year, month, and day part from the date
  const date = new Date(dateString);
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  const time = date.toLocaleString("default", { timeStyle: "short" });

  // Generate yyyy-mm-dd date string
  const formattedDate = day + "/" + month + "/" + year;
  return { date: formattedDate, time };
};

// Get previous monday
export const getPreviousWeek = (weekday) => {
  let date = new Date();
  let day = date.getDay();
  let prevWeekDay = new Date();

  if (date.getDay() == 1) {
    prevWeekDay.setDate(date.getDate() - 8);
  } else {
    prevWeekDay.setDate(date.getDate() - (7 + day));
  }

  prevWeekDay.setHours(0);
  prevWeekDay.setMinutes(0);
  prevWeekDay.setMilliseconds(0);

  if (weekday === 7) {
    prevWeekDay.setDate(prevWeekDay.getDate() + 7);
  }

  //console.log(prevWeekDay.setDate(prevWeekDay.getDate() + 5));
  return prevWeekDay;
};

export const getCurrentWeek = (weekDay) => {
  let curr = new Date();
  curr.setHours(0);
  curr.setMinutes(0);
  curr.setMilliseconds(0);

  let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
  let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

  if (weekDay === 0) {
    return firstday;
  } else {
    return lastday;
  }
};

export const convertToTeraByte = (value, unit = "gigabyte") => {
  if (!value) return 0;

  switch (unit) {
    case "gigabyte":
      return Math.ceil(value / 1000);
    default:
      return Math.ceil(value);
  }
};

export const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeString = (stringToCap) => {
  let stringCap = "";
  const words = stringToCap.split();
  words.forEach((word, index) => {
    if (index === words.length - 1) {
      stringCap += capitalizeWord(word);
    } else {
      stringCap += capitalizeWord(word) + " ";
    }
  });
  return stringCap;
};

export const getTimeLength = (startDateString) => {
  const currentDate = new Date();
  const startDate = new Date(startDateString);
  /* let days;
  let hours;
  let minutes;
  let seconds; */

  let timeLeftInSeconds = Math.round(
    (currentDate.getTime() - startDate.getTime()) / 1000
  );

  const days = Math.floor(timeLeftInSeconds / (60 * 60 * 24));

  timeLeftInSeconds = timeLeftInSeconds - days * 60 * 60 * 24;

  const hours = Math.floor(timeLeftInSeconds / (60 * 60));

  timeLeftInSeconds = timeLeftInSeconds - hours * 60 * 60;

  const minutes = Math.floor(timeLeftInSeconds / 60);

  timeLeftInSeconds = timeLeftInSeconds - minutes * 60;

  return `${days}D ${hours}H ${minutes}M ${timeLeftInSeconds}S`;
};
