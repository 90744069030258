import { useState } from "react";
import { ReactComponent as ProjectIcon } from "../../assets/svg/icons/projecticon.svg";
import { appConfig } from "../../config";
import { getDateTime } from "../../utils";
import { networkIcons } from "../layouts/page-layout/PageLayout";
import { ReactComponent as Options } from "../../assets/svg/icons/options.svg";
import { ReactComponent as Open } from "../../assets/svg/icons/file_open.svg";
import { ReactComponent as Terminate } from "../../assets/svg/icons/terminate.svg";
import { useNavigate } from "react-router-dom";
import { TerminateModal } from "../layouts/page-layout/Modals";

const ApiRow = ({ project, header, activeProject }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const projectNetwork = appConfig.networks?.filter((network) => {
    return network.id === project?.networkId;
  });
  const handleOptionClick = (e) => {
    e.stopPropagation();
    if (selectedOption !== project?.id) {
      setSelectedOption(project?.id);
    } else {
      setSelectedOption(null);
    }
  };
  return (
    <div className="w-full">
      {header ? (
        <div className="bg-[#F1F6FF] mt-5 cursor-default h-[60px] w-full flex items-center text-[14px] px-3 md:px-8">
          <p className="w-[58%] md:w-[30%]">Project Name</p>
          <p className="w-[38%] md:w-[30%]">Network</p>
          <p className="hidden md:flex w-[20%]">Status</p>
          <p className="hidden md:flex w-[17%]">Date Created</p>
        </div>
      ) : (
        projectNetwork?.length > 0 && (
          <div
            className="bg-white border-b border-[#EBEBEB] cursor-pointer h-[60px] w-full flex items-center text-[14px] px-3 md:px-8"
            onClick={() => {
              activeProject && navigate(`/api-services/${project?.id}`);
            }}
          >
            <div className="w-[58%] md:w-[30%] flex items-center gap-2">
              <ProjectIcon />
              <p className="truncate pr-5">{project?.projectName}</p>
            </div>
            <div className="w-[58%] md:w-[30%] flex items-center">
              <div className="scale-[0.6] -ml-3">
                {networkIcons[projectNetwork[0]?.name]}
              </div>
              {projectNetwork[0]?.name}
            </div>
            <div className="hidden md:flex w-[20%]">
              {activeProject ? (
                <div className="w-[70px] h-7 flex items-center justify-between bg-[#ECFBEF] text-[#2C9067] border-[0.5px] border-[#2C9067] rounded-[80px] p-2 font-[400] text-[13px]">
                  <div className="w-2 h-2 bg-[#2C9067] rounded-full"></div>
                  <p>Active</p>
                </div>
              ) : (
                <div className="w-[105px] h-7 flex items-center justify-between bg-[#FCEAE7] text-[#C3240F] border-[0.5px] border-[#C3240F] rounded-[80px] p-2 font-[400] text-[13px]">
                  <div className="w-2 h-2 bg-[#C3240F] rounded-full"></div>
                  <p>Terminated</p>
                </div>
              )}
            </div>
            <div className="hidden md:flex w-[17%]">
              <p className="font-[400]">
                {getDateTime(project?.createdAt).date}
                <span className="text-[11px] text-[#797979]">
                  {getDateTime(project?.createdAt).time}
                </span>
              </p>
            </div>
            <div className="w-5 relative z-[999]">
              <button
                className="relative w-5 flex items-center justify-center cursor-auto"
                onClick={handleOptionClick}
                onBlur={() => setSelectedOption(null)}
              >
                <Options />
                {selectedOption === project?.id && (
                  <div className="absolute right-10 top-0 w-[200px] text-[13px] font-[200] text-[#717171] flex flex-col bg-white px-5 py-3 rounded-[8px] shadow-md">
                    <div
                      onClick={() => {
                        activeProject &&
                          navigate(`/api-services/${project?.id}`);
                      }}
                      className={`flex items-center gap-2 py-4 pl-5 ${
                        activeProject && "border-b-[0.5px] border-b-[#D8D8D8]"
                      } cursor-pointer`}
                    >
                      <Open className="scale-90" />
                      <p>Open</p>
                    </div>
                    {activeProject && (
                      <div
                        onClick={() => {
                          setTerminateModalIsOpen(!terminateModalIsOpen);
                        }}
                        className="flex items-center gap-2 py-4 pl-5 cursor-pointer"
                      >
                        <Terminate className="scale-90" />
                        <p>Terminate</p>
                      </div>
                    )}
                  </div>
                )}
              </button>
            </div>
          </div>
        )
      )}
      <div>
        <TerminateModal
          terminateModalIsOpen={terminateModalIsOpen}
          setTerminateModalIsOpen={setTerminateModalIsOpen}
          project={{ id: project?.id, orgId: project?.createdBy }}
          projectType={"api"}
        />
      </div>
    </div>
  );
};

export default ApiRow;
