import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../assets/svg/icons/back.svg";
import { ReactComponent as Bin } from "../../assets/svg/icons/tiny-bin.svg";
import { ReactComponent as Copy } from "../../assets/svg/icons/content_copy.svg";
import Loader from "../../assets/img/loader.gif";
import { useEffect, useState } from "react";
import { TerminateModal } from "../../components/layouts/page-layout/Modals";
import {
  NETWORK_STATE,
  alertError,
  capitalizeString,
  getTimeLength,
} from "../../utils";
import { useAuth } from "../../context/authContext";
import { getParachainProject } from "../../services/ProjectsService";
import ParachainCPUChart from "../../components/parachain-nodes/charts/CPUChart";
import { useLiveUpdates } from "../../context/liveUpdates";
import { appConfig } from "../../config";
import { nodeResponse } from "./MockData";

const LiveUptime = ({ startDate }) => {
  const [uptime, setUptime] = useState("");

  useEffect(() => {
    setInterval(() => {
      setUptime(getTimeLength(startDate));
    }, 1000);
  }, []);

  return <>{uptime}</>;
};

const ParachainProjectInsight = () => {
  const navigate = useNavigate();
  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const [liveProjectStatus, setLiveProjectStatus] = useState("active");
  const location = useLocation();
  const [project, setProject] = useState(null);
  const auth = useAuth();
  const [status, setStatus] = useState(NETWORK_STATE.LOADING);
  const [isCopied, setIsCopied] = useState(false);
  const [nodeTypes, setNodeTypes] = useState([
    "validator",
    "archival",
    "full",
    "boot",
  ]);
  const [activeNodeType, setActiveNodeType] = useState("");
  const [parachainNodes, setParachainNodes] = useState([]);
  const { reloadParachainList, setReloadParachainList } = useLiveUpdates();

  const fetchProject = async () => {
    const pathSplit = location.pathname.split("/");
    const parachainId = pathSplit[pathSplit.length - 1];
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getParachainProject(parachainId);
      //console.log(response);
      const userNodes = response.data?.data?.parachainNodes?.rows[0];
      auth.setHeaderText(["Parachain Nodes", userNodes[0]?.projectName]);
      const allNodeTypes = {};
      userNodes.forEach((node) => {
        const nodeType = node.nodeType.split(" ")[0];
        if (!allNodeTypes[nodeType]) {
          allNodeTypes[nodeType] = [node];
        } else {
          allNodeTypes[nodeType].push(node);
        }
      });
      setActiveNodeType(Object.keys(allNodeTypes)[0]);
      setParachainNodes(allNodeTypes);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    reloadParachainList && navigate(appConfig.nav.routes.nodes);
  }, [reloadParachainList]);

  const handleCopy = (id, text) => {
    navigator.clipboard.writeText(text);
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div className="w-full">
      {status === NETWORK_STATE.LOADING ? (
        <img
          src={Loader}
          alt="Loading..."
          className="self-center mx-auto py-20"
        />
      ) : status === NETWORK_STATE.SUCCESS && parachainNodes ? (
        <div className="dedicatedProject">
          <div className="dedicatedProject__header">
            <button
              className="create__back"
              onClick={() => {
                navigate("/parachain-nodes");
              }}
            >
              <Back className="scale-[0.9]" />
              <p>Go Back</p>
            </button>
            <div className="flex items-center gap-3">
              <button
                className={`dedicatedProject__header-btn ${
                  liveProjectStatus === "terminate" &&
                  "cursor-not-allowed hover:opacity-70"
                }`}
                onClick={() => setTerminateModalIsOpen(!terminateModalIsOpen)}
                disabled={liveProjectStatus === "terminate"}
              >
                <Bin />
              </button>
              <div className="w-fit bg-[#F1F6FF bg-white p-3 flex items-center rounded-[8px] gap-5 self-center mx-auto">
                {Object.keys(parachainNodes).map((key, index) => {
                  return (
                    <button
                      key={index}
                      className={`py-2 px-3 rounded-[8px] text-[16px] ${
                        activeNodeType === key
                          ? "bg-blue text-white"
                          : "bg-transparent"
                      }`}
                      type="button"
                      onClick={() => setActiveNodeType(key)}
                    >
                      {key === "rpc"
                        ? `RPC`
                        : `${capitalizeString(key)} Nodes (${
                            parachainNodes[key][0]?.count
                          })`}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="project-insight__graph mt-5">
            <div className="w-full flex items-center justify-between"></div>
            {parachainNodes[activeNodeType] ? (
              <ParachainCPUChart projects={parachainNodes[activeNodeType]} />
            ) : (
              <img
                src={Loader}
                alt="Loading..."
                className="self-center py-20"
              />
            )}
          </div>

          <div className="dedicatedProject__dataBox">
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Storage size</p>
              <p className="dedicatedProject__insight-value">
                {parachainNodes[activeNodeType][0]?.storageSize}
              </p>
            </div>
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Memory GB</p>
              <p className="dedicatedProject__insight-value">
                {parachainNodes[activeNodeType][0]?.memorySize}
              </p>
            </div>
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Uptime</p>
              <p className="dedicatedProject__insight-value">
                {liveProjectStatus === "active" ? (
                  <LiveUptime
                    startDate={parachainNodes[activeNodeType][0]?.createdAt}
                  />
                ) : (
                  `${capitalizeString(liveProjectStatus)}d`
                )}
              </p>
            </div>
          </div>

          {[parachainNodes[activeNodeType][0]].map((node, index) => {
            return (
              <div key={index} className="dedicatedProject__rpcApi">
                <div className="dedicatedProject__insight h-[80px]">
                  <div className="dedicatedProject__insight-title flex gap-2 items-center">
                    RPC Endpoint
                  </div>
                  <div className="dedicatedProject__rpcApi-value">
                    <div className="w-full flex justify-between items-center">
                      <p className="w-full font-[200] text-[14px] text-ellipsis truncate">
                        {`https://${
                          node?.referenceId
                        }-node.${"blockops.network"}`}
                      </p>
                      <div
                        className="p-2 cursor-pointer hover:scale-[1.05]"
                        onClick={() =>
                          handleCopy(
                            `RPC-${node.referenceId}`,
                            `https://${
                              node?.referenceId
                            }-node.${"blockops.network"}`
                          )
                        }
                      >
                        <Copy />
                        {isCopied.id === `RPC-${node.referenceId}` && (
                          <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
                            Copied!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dedicatedProject__insight h-[80px]">
                  <div className="dedicatedProject__insight-title flex gap-2 items-center">
                    API Key
                  </div>
                  <div className="dedicatedProject__rpcApi-value">
                    <div className="w-full flex justify-between items-center">
                      <p className="w-full font-[200] text-[14px] text-ellipsis truncate">
                        {node?.apiKey?.slice(0, 10)}
                        ********
                      </p>
                      <div
                        className="p-2 cursor-pointer hover:scale-[1.05]"
                        onClick={() =>
                          handleCopy(
                            `API-${node.referenceId}`,
                            `${node?.apiKey}`
                          )
                        }
                      >
                        <Copy />
                        {isCopied.id === `API-${node.referenceId}` && (
                          <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
                            Copied!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div>
            <TerminateModal
              terminateModalIsOpen={terminateModalIsOpen}
              setTerminateModalIsOpen={setTerminateModalIsOpen}
              project={parachainNodes[activeNodeType][0]}
              projectType={"parachain"}
            />
          </div>
        </div>
      ) : (
        <div className="mt-5 text-[14px]">
          <p>An Error has occured</p>
          <p
            className="underline text-blue cursor-pointer"
            onClick={fetchProject}
          >
            Retry
          </p>
        </div>
      )}
    </div>
  );
};

export default ParachainProjectInsight;
