import { useEffect, useState } from "react";
import { useAuth } from "../../../context/authContext";
import { NETWORK_STATE, capitalizeWord } from "../../../utils";
import { FailedNetwork, InsightContainer } from "./HelperComponents";
import { ReactComponent as ArrowDown } from "../../../assets/svg/icons/arrow-down.svg";
import { ReactComponent as Composable } from "../../../assets/svg/networks/Composable.svg";
import { ReactComponent as DateIcon } from "../../../assets/svg/icons/date_range.svg";
import { ReactComponent as FilterIcon } from "../../../assets/svg/icons/sort.svg";
import { ReactComponent as Flow } from "../../../assets/svg/networks/Flow.svg";
import { ReactComponent as Kusama } from "../../../assets/svg/networks/Kusama.svg";
import { ReactComponent as Moonriver } from "../../../assets/svg/networks/Moonriver.svg";
import { ReactComponent as PageArrow } from "../../../assets/svg/icons/page-arrow.svg";
import { ReactComponent as Polkadot } from "../../../assets/svg/networks/Polkadot.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/icons/search.svg";
import EmptyTerminated from "../../../assets/img/no-terminated.png";
import { ReactComponent as ServiceIcon } from "../../../assets/svg/icons/service-filter-icon.svg";
import ApiRow from "../../api-services/ApiRow";
import Loader from "../../../assets/gifs/loader.gif";
import Moonbeam from "../../../assets/img/Moonbeam.png";
import NodeRow from "../../dedicated-nodes/NodeRow";
import UserRow from "../../user/UserRow";

export const networkIcons = {
  Polkadot: <Polkadot />,
  Kusama: <Kusama />,
  Composable: <Composable />,
  Moonbeam: <img src={Moonbeam} alt="Moonbeam" width={70} />,
  Moonriver: <Moonriver />,
  Flow: <Flow />,
  Rococo: "",
  Subspace: "",
};

const PageLayout = ({
  pageType,
  analytics,
  allFilters,
  setAllFilters,
  filteredItems,
  fetchItems,
  networkStatus,
  allPendingProjects,
}) => {
  const auth = useAuth();
  const [currentPage, setCurrentPage] = useState({ active: 1, terminated: 1 });
  const [displayedFilters, setDisplayedFilters] = useState({
    active: ["Date"],
    terminated: ["Date"],
  });
  const defaultDropdownState = {
    active: {
      addFilters: false,
      date: false,
      services: false,
    },
    terminated: {
      addFilters: false,
      date: false,
      services: false,
    },
  };

  const [dropdowns, setDropdowns] = useState(defaultDropdownState);
  const [currentDateFilter, setCurrentDateFilter] = useState({
    active: null,
    terminated: null,
  });
  const [currentServiceFilters, setCurrentServiceFilters] = useState({
    active: [],
    terminated: [],
  });
  const [pageItems, setPageItems] = useState({ active: [], terminated: [] });
  const [totalPages, setTotalPages] = useState({ active: 1, terminated: 1 });
  const possibleStates =
    pageType === "user" ? ["active"] : ["active", "terminated"];
  const itemsPerPage = 5;

  // Function to handle date change
  const handleDateChange = (e, itemState) => {
    const filter1 = document.getElementById(`${itemState}-date-filter-1`);
    const filter2 = document.getElementById(`${itemState}-date-filter-2`);
    const filter3 = document.getElementById(`${itemState}-date-filter-3`);
    const filter4 = document.getElementById(`${itemState}-date-filter-4`);
    const filter5 = document.getElementById(`${itemState}-date-filter-5`);

    const filterMapping = {
      1: filter1,
      15: filter2,
      30: filter3,
      90: filter4,
      365: filter5,
    };

    Object.keys(filterMapping).map((key) => {
      if (key !== e.target.value) {
        filterMapping[key].checked = false;
      }
    });
    if (currentDateFilter[itemState] === e.target.value) {
      filterMapping[currentDateFilter[itemState]].checked = false;
      const newCurrentFilter = { ...currentDateFilter };
      newCurrentFilter[itemState] = null;
      setCurrentDateFilter(newCurrentFilter);
      const newFilters = { ...allFilters };
      newFilters[itemState].date = null;
      setAllFilters(newFilters);
    } else {
      const newCurrentFilter = { ...currentDateFilter };
      newCurrentFilter[itemState] = e.target.value;
      setCurrentDateFilter(newCurrentFilter);
      const newFilters = { ...allFilters };
      newFilters[itemState].date = e.target.value;
      setAllFilters(newFilters);
    }
    const p = { ...currentPage };
    p[itemState] = 1;
    setCurrentPage(p);
  };

  const handleServiceChange = (e, itemState) => {
    const filter1 = document.getElementById(`${itemState}-service-filter-1`);
    const filter2 = document.getElementById(`${itemState}-service-filter-2`);
    const filter3 = document.getElementById(`${itemState}-service-filter-3`);
    const filter4 = document.getElementById(`${itemState}-service-filter-4`);

    const filterMapping = { 1: filter1, 2: filter2, 3: filter3, 4: filter4 };
    if (currentServiceFilters[itemState]?.includes(e.target.value)) {
      // Update current service filters
      filterMapping[e.target.value].checked = false;
      let newServiceFilter = { ...currentServiceFilters };
      newServiceFilter[itemState].splice(
        newServiceFilter[itemState].indexOf(e.target.value),
        1
      );
      setCurrentServiceFilters(newServiceFilter);
      // Update all filters
      const newFilters = { ...allFilters };
      newFilters[itemState]?.services.splice(
        newFilters[itemState]?.services.indexOf(e.target.value),
        1
      );
      setAllFilters(newFilters);
    } else {
      // Update current service filters
      const newCurrentFilter = { ...currentServiceFilters };
      newCurrentFilter[itemState].push(e.target.value);
      setCurrentServiceFilters(newCurrentFilter);
      // Update all filters
      const newFilters = { ...allFilters };
      newFilters[itemState].services.push(e.target.value);
      setAllFilters(newFilters);
    }
    const p = { ...currentPage };
    p[itemState] = 1;
    setCurrentPage(p);
  };

  //Break items into pages
  useEffect(() => {
    const activeStart = (currentPage.active - 1) * itemsPerPage;
    const activeEnd = itemsPerPage * (currentPage.active - 1) + itemsPerPage;
    const termStart = (currentPage.terminated - 1) * itemsPerPage;
    const termEnd = itemsPerPage * (currentPage.terminated - 1) + itemsPerPage;
    setPageItems({
      ...pageItems,
      active: filteredItems?.active?.slice(activeStart, activeEnd),
      terminated: filteredItems?.terminated?.slice(termStart, termEnd),
    });
    setTotalPages({
      ...totalPages,
      active: Math.ceil(filteredItems.active.length / itemsPerPage),
      terminated: Math.ceil(filteredItems.terminated.length / itemsPerPage),
    });
  }, [
    filteredItems.active.length,
    currentPage.active,
    filteredItems.terminated.length,
    currentPage.terminated,
  ]);

  const handleFilterChange = (e, itemState) => {
    const newFilters = { ...allFilters };
    newFilters[itemState].searchString = e.target.value;
    setAllFilters(newFilters);
    const p = { ...currentPage };
    p[itemState] = 1;
    setCurrentPage(p);
  };

  // Change header text
  useEffect(() => {
    auth.setHeaderText(["Users"]);
  }, []);

  document.title = "BLP Admin | User";

  return (
    <div className="user">
      <div className="w-full justify-end">
        {(allPendingProjects?.deleting?.length > 0 ||
          allPendingProjects?.creating?.length > 0) && (
          <div className="flex flex-col gap-5 md:flex-row items-start md:items-center justify-end mt-3 pb-5">
            {allPendingProjects?.deleting?.length > 0 && (
              <div className="relative text-[#C03030] font-[500] text-sm shadow-md rounded-[85px] px-4 py-3 bg-white flex items-center gap-2">
                <div className="w-2 h-2 bg-[#C03030] rounded-full"></div>
                <p>Deleting in progress</p>
                <p className="absolute h-5 w-5 -top-[10px] flex items-center justify-center right-1 bg-[#C03030] rounded-full text-white text-sm">
                  {allPendingProjects?.deleting?.length}
                </p>
              </div>
            )}
            {allPendingProjects?.creating?.length > 0 && (
              <div className="relative text-[#2C9067] font-[500] text-sm shadow-md rounded-[85px] px-4 py-3 bg-white flex items-center gap-2">
                <div className="w-2 h-2 bg-[#2C9067] rounded-full"></div>
                <p>Creating in progress</p>
                <p className="absolute h-5 w-5 -top-[10px] flex items-center justify-center right-1 bg-[#2C9067] rounded-full text-white text-sm">
                  {allPendingProjects?.creating?.length}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="user__hero">
        <InsightContainer>
          {networkStatus.active === NETWORK_STATE.LOADING ? (
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
          ) : networkStatus.active === NETWORK_STATE.ERROR ? (
            <FailedNetwork
              fetchItems={() => {
                fetchItems("active");
              }}
            />
          ) : (
            <div className="w-full h-full flex flex-col justify-center gap-2">
              {analytics?.cardOne?.icon && (
                <div className="h-[50px]">{analytics?.cardOne?.icon}</div>
              )}
              <h5 className="header-text">{analytics?.cardOne?.title}</h5>
              <p className="info-text">
                <span className={analytics?.cardOne?.smallText?.iconBg}>
                  {analytics?.cardOne?.smallText?.icon === "neutral"
                    ? "--"
                    : analytics?.cardOne?.smallText?.icon}
                  {analytics?.cardOne?.smallText?.iconText}
                </span>
                {analytics?.cardOne?.smallText?.text}
              </p>
              <p className="text-[25px] font-[600]">
                {analytics?.cardOne?.count}
              </p>
            </div>
          )}
        </InsightContainer>
        <InsightContainer>
          {networkStatus.terminated === NETWORK_STATE.LOADING ? (
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
          ) : networkStatus.terminated === NETWORK_STATE.ERROR ? (
            <FailedNetwork
              fetchItems={() => {
                fetchItems("terminated");
              }}
            />
          ) : (
            <div className="w-full h-full flex flex-col justify-center gap-2">
              {analytics?.cardTwo?.icon && (
                <div className="h-[50px]">{analytics.cardTwo.icon}</div>
              )}
              <h5 className="header-text">{analytics?.cardTwo?.title}</h5>
              <p className="info-text">
                <span className={analytics?.cardTwo?.smallText?.iconBg}>
                  {analytics?.cardTwo?.smallText?.icon === "neutral"
                    ? "--"
                    : analytics?.cardTwo?.smallText?.icon}
                  {analytics?.cardTwo?.smallText?.iconText}
                </span>
                {analytics?.cardTwo?.smallText?.text}
              </p>
              <p className="text-[25px] font-[600]">
                {analytics?.cardTwo?.count}
              </p>
            </div>
          )}
        </InsightContainer>
        <InsightContainer>
          <div className="w-full h-full flex flex-col justify-center gap-3">
            <h5 className="self-center text-[27px] font-[600]">Version 1.0</h5>
          </div>
        </InsightContainer>
      </div>
      {possibleStates.map((itemState, index) => {
        if (networkStatus) {
          return (
            <div key={index} className="users-list">
              <h1 className="self-start">
                {`${capitalizeWord(itemState)} ${
                  pageType === "user"
                    ? "Users"
                    : pageType === "node"
                    ? "Nodes"
                    : "Projects"
                }`}
                {analytics?.itemsLength &&
                  analytics?.itemsLength[itemState] > 0 &&
                  `(${analytics.itemsLength[itemState]})`}
              </h1>
              <div className="users-list__display">
                {networkStatus[itemState] === NETWORK_STATE.LOADING ? (
                  <img
                    src={Loader}
                    alt="Loading..."
                    width={20}
                    className="mx-auto mt-20 self-center"
                  />
                ) : networkStatus[itemState] === NETWORK_STATE.ERROR ? (
                  <FailedNetwork fetchItems={() => fetchItems(itemState)} />
                ) : analytics?.itemsLength[itemState] === 0 ? (
                  <div className="w-full h-auto flex justify-center my-auto">
                    <h2 className="absolute top-[150px] max-w-[250px] text-center text-3xl font-bold">
                      No {capitalizeWord(itemState)} Projects
                    </h2>
                    <img
                      src={EmptyTerminated}
                      width={600}
                      alt="No terminated projects"
                    />
                  </div>
                ) : (
                  <div className="w-full">
                    <div className="users-list__display-header">
                      <div className="w-[230px] h-[40px] self-start border-[0.5px] border-[#EBEBEB] rounded-[8px] flex gap-3 items-center justify-between px-3">
                        <SearchIcon />
                        <input
                          type="text"
                          placeholder="Search Projects"
                          className="w-[200px] h-[37px] outline-none border-none font-[200] text-[14px]"
                          onChange={(e) => handleFilterChange(e, itemState)}
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        {displayedFilters[itemState]?.includes("Date") && (
                          <div className="relative">
                            <button
                              type="button"
                              className="date-btn"
                              onClick={() => {
                                const newDropState = {
                                  ...defaultDropdownState,
                                };
                                newDropState[itemState] = {
                                  ...newDropState[itemState],
                                  date: !dropdowns[itemState].date,
                                };
                                setDropdowns(newDropState);
                              }}
                            >
                              <DateIcon className="scale-[0.9]" />
                              Date
                              <ArrowDown
                                fill="black"
                                className={`scale-[1.4] ${
                                  dropdowns[itemState].date
                                    ? "rotate-[-90deg]"
                                    : ""
                                }`}
                              />
                            </button>
                            {
                              <div
                                tabIndex={0}
                                className={`dropdown-container ${
                                  dropdowns[itemState].date
                                    ? "flex flex-col"
                                    : "hidden"
                                }`}
                              >
                                <div className="dropdown-option rule">
                                  <input
                                    id={`${itemState}-date-filter-1`}
                                    type={"radio"}
                                    value={1}
                                    onClick={(e) =>
                                      handleDateChange(e, itemState)
                                    }
                                  />
                                  Last Day
                                </div>
                                <div className="dropdown-option rule">
                                  <input
                                    id={`${itemState}-date-filter-2`}
                                    type={"radio"}
                                    value={15}
                                    onClick={(e) =>
                                      handleDateChange(e, itemState)
                                    }
                                  />
                                  Last 15 Days
                                </div>
                                <div className="dropdown-option rule">
                                  <input
                                    id={`${itemState}-date-filter-3`}
                                    type={"radio"}
                                    value={30}
                                    onClick={(e) =>
                                      handleDateChange(e, itemState)
                                    }
                                  />
                                  Last 30 Days
                                </div>
                                <div className="dropdown-option rule">
                                  <input
                                    id={`${itemState}-date-filter-4`}
                                    type={"radio"}
                                    value={90}
                                    onClick={(e) =>
                                      handleDateChange(e, itemState)
                                    }
                                  />
                                  Last Quarter
                                </div>
                                <div className="dropdown-option">
                                  <input
                                    id={`${itemState}-date-filter-5`}
                                    type={"radio"}
                                    value={365}
                                    onClick={(e) =>
                                      handleDateChange(e, itemState)
                                    }
                                  />
                                  Last Year
                                </div>
                              </div>
                            }
                          </div>
                        )}
                        {pageType === "user" &&
                          displayedFilters[itemState].includes("Services") && (
                            <div className="relative">
                              <button
                                type="button"
                                className="service-btn"
                                onClick={() => {
                                  const newDropState = {
                                    ...defaultDropdownState,
                                  };
                                  newDropState[itemState] = {
                                    ...newDropState[itemState],
                                    services: !dropdowns[itemState].services,
                                  };
                                  setDropdowns(newDropState);
                                }}
                              >
                                <ServiceIcon className="scale-[0.9]" />
                                Services
                                <ArrowDown
                                  fill="black"
                                  className={`scale-[1.4] ${
                                    dropdowns[itemState].services
                                      ? "rotate-[-90deg]"
                                      : ""
                                  }`}
                                />
                              </button>
                              {
                                <div
                                  tabIndex={0}
                                  className={`dropdown-container ${
                                    dropdowns[itemState].services
                                      ? "flex flex-col"
                                      : "hidden"
                                  }`}
                                >
                                  <div className="dropdown-option rule">
                                    <input
                                      id={`${itemState}-service-filter-1`}
                                      type={"radio"}
                                      value={1}
                                      onClick={(e) =>
                                        handleServiceChange(e, itemState)
                                      }
                                    />
                                    Api Services
                                  </div>
                                  <div className="dropdown-option rule">
                                    <input
                                      id={`${itemState}-service-filter-2`}
                                      type={"radio"}
                                      value={2}
                                      onClick={(e) =>
                                        handleServiceChange(e, itemState)
                                      }
                                    />
                                    Validator Nodes
                                  </div>
                                  <div className="dropdown-option rule">
                                    <input
                                      id={`${itemState}-service-filter-3`}
                                      type={"radio"}
                                      value={3}
                                      onClick={(e) =>
                                        handleServiceChange(e, itemState)
                                      }
                                    />
                                    Dedicated Nodes
                                  </div>
                                  <div className="dropdown-option">
                                    <input
                                      id={`${itemState}-service-filter-4`}
                                      type={"radio"}
                                      value={4}
                                      onClick={(e) =>
                                        handleServiceChange(e, itemState)
                                      }
                                    />
                                    Data Services
                                  </div>
                                </div>
                              }
                            </div>
                          )}
                        <div className="relative">
                          <button
                            type="button"
                            onClick={() => {
                              const newDropState = { ...defaultDropdownState };
                              newDropState[itemState] = {
                                ...newDropState[itemState],
                                addFilters: !dropdowns[itemState].addFilters,
                              };
                              setDropdowns(newDropState);
                            }}
                            onBlur={() => {
                              const newDropState = { ...defaultDropdownState };
                              newDropState[itemState] = {
                                ...newDropState[itemState],
                                addFilters: false,
                              };
                              setDropdowns(newDropState);
                            }}
                            className="filter-btn"
                          >
                            <FilterIcon className={`scale-[0.9]`} />
                            Add Filters
                            {dropdowns[itemState].addFilters && (
                              <div className="dropdown-container">
                                <div
                                  onClick={() => {
                                    if (
                                      !displayedFilters[itemState]?.includes(
                                        "Date"
                                      )
                                    ) {
                                      const n =
                                        displayedFilters[itemState]?.push(
                                          "Date"
                                        );
                                      const newFilters = {
                                        ...displayedFilters,
                                      };
                                      newFilters[itemState] = n;
                                      setDisplayedFilters(newFilters);
                                    }
                                    const newDropState = {
                                      ...defaultDropdownState,
                                    };
                                    newDropState[itemState] = {
                                      ...newDropState[itemState],
                                      addFilters: false,
                                    };
                                    setDropdowns(newDropState);
                                  }}
                                  className={`dropdown-option ${
                                    pageType === "users" && "rule"
                                  }`}
                                >
                                  <DateIcon className="scale-[0.9]" />
                                  <p>Date</p>
                                </div>
                                {pageType === "user" && (
                                  <div
                                    onClick={() => {
                                      if (
                                        !displayedFilters[itemState]?.includes(
                                          "Services"
                                        )
                                      ) {
                                        displayedFilters[itemState]?.push(
                                          "Services"
                                        );
                                      }
                                      const newDropState = {
                                        ...defaultDropdownState,
                                      };
                                      newDropState[itemState] = {
                                        ...newDropState[itemState],
                                        addFilters: false,
                                      };
                                      setDropdowns(newDropState);
                                    }}
                                    className={`dropdown-option`}
                                  >
                                    <ServiceIcon className="scale-[0.9]" />
                                    <p>Services</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div>
                      {pageType === "user" && <UserRow header />}
                      {pageType === "api" && <ApiRow header />}
                      {pageType.includes("node") && <NodeRow header />}
                      {pageItems[itemState]?.map((item, index) => {
                        return (
                          <div key={index}>
                            {pageType === "user" && <UserRow user={item} />}
                            {pageType === "api" && (
                              <ApiRow
                                project={item}
                                activeProject={itemState === "active"}
                              />
                            )}
                            {pageType.includes("node") && (
                              <NodeRow
                                project={item}
                                activeProject={itemState === "active"}
                                pageType={pageType}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {analytics?.itemsLength[itemState] === 0 ? (
                  <div></div>
                ) : (
                  <div className="users-list__display-footer">
                    <button
                      type="button"
                      onClick={() => {
                        const newCurrentPage = { ...currentPage };
                        newCurrentPage[itemState] =
                          newCurrentPage[itemState] - 1;
                        currentPage[itemState] > 1 &&
                          setCurrentPage(newCurrentPage);
                      }}
                      className="prev-btn"
                    >
                      <PageArrow className="rotate-[180deg]" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newCurrentPage = { ...currentPage };
                        newCurrentPage[itemState] =
                          newCurrentPage[itemState] + 1;
                        currentPage[itemState] < totalPages[itemState] &&
                          setCurrentPage(newCurrentPage);
                      }}
                      className="next-btn"
                    >
                      Next Page
                      <PageArrow />
                    </button>
                    <div className="absolute right-7 text-[12px]">
                      Page {currentPage[itemState]} of{" "}
                      {totalPages[itemState] ? totalPages[itemState] : 1}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default PageLayout;
