import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAuth } from "../../context/authContext";
import { ReactComponent as OpenEyeIcon } from "../../assets/svg/icons/open_eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/svg/icons/close_eye.svg";
import { ReactComponent as Profile } from "../../assets/svg/icons/profile.svg";
import Error from "../../assets/img/error.png";
import {
  alertError,
  alertSuccess,
  containsSpecialChars,
  NETWORK_STATE,
} from "../../utils";
import { updatePassword, updateProfile } from "../../services/AuthService";
import { PasswordCheck } from "../../components/layouts/Auth";
import Heading from "../../components/layouts/Heading";

export default function Settings() {
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const [updateStatus, setUpdateStatus] = useState(NETWORK_STATE.IDLE);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profileActive, setProfileActive] = useState(true);
  const [passwordActive, setPasswordActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const auth = useAuth();
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const ErrorText = (props) => {
    return (
      <div className="relative w-full flex gap-2">
        <img width={20} src={Error} alt="err" className="absolute left-0" />
        <p className="ml-7 w-full">{props.text}</p>
      </div>
    );
  };

  const [allPassed, setAllPassed] = useState(false);

  const pwd_formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required(<ErrorText text="Old Password is required." />)
        .min(5, <ErrorText text="minimum of 5 characters" />),
      newPassword: Yup.string().required(
        <ErrorText text="New password is required" />
      ),
    }),
    onSubmit: async (values) => {
      const obj = {
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      if (updateStatus !== NETWORK_STATE.LOADING) {
        setUpdateStatus(NETWORK_STATE.LOADING);
        try {
          const response = await updatePassword(obj);
          setUpdateStatus(NETWORK_STATE.SUCCESS);
          alertSuccess(response.data.message);
        } catch (err) {
          console.log(err, "errr");
          alertError(
            err?.response?.data?.message || "Something went wrong, try again"
          );
          setUpdateStatus(NETWORK_STATE.ERROR);
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(3, "Minimum of 3 characters."),
      lastName: Yup.string()
        .required("Last name is required")
        .min(3, "Minimum of 3 characters."),
    }),
    onSubmit: async (values) => {
      delete values.phone;
      if (updateStatus !== NETWORK_STATE.LOADING) {
        setUpdateStatus(NETWORK_STATE.LOADING);
        try {
          const response = await updateProfile(values);
          setUpdateStatus(NETWORK_STATE.SUCCESS);
          alertSuccess(response.data.message);

          // Update the user in the localStorage
          const currentUserData = JSON.parse(localStorage.getItem("user"));
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...currentUserData,
              user: {
                id: currentUserData.user.id,
                email: currentUserData.user.email,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            })
          );
          setTimeout(() => window.location.reload(), 500);
        } catch (err) {
          alertError(
            err?.response?.data?.message || "Something went wrong, try again"
          );
          setUpdateStatus(NETWORK_STATE.ERROR);
        }
      }
    },
  });

  React.useEffect(() => {
    auth.setHeaderText(["Settings"]);
    formik.setValues({
      firstName: auth.user.firstName,
      lastName: auth.user.lastName,
    });
  }, []);

  React.useEffect(() => {
    if (
      formik.values.firstName !== auth.user.firstName ||
      formik.values.lastName !== auth.user.lastName
    )
      setEdit(true);
    else setEdit(false);
  }, [formik.values.firstName, formik.values.lastName]);

  React.useEffect(() => {
    setAllPassed(false);
    pwd_formik.values.newPassword &&
      /[a-z]/.test(pwd_formik.values.newPassword) &&
      /[A-Z]/.test(pwd_formik.values.newPassword) &&
      /[0-9]/.test(pwd_formik.values.newPassword) &&
      containsSpecialChars(pwd_formik.values.newPassword) &&
      pwd_formik.values.newPassword.length > 7 &&
      setAllPassed(true);
  }, [pwd_formik?.values?.newPassword]);

  return (
    <div className="flex justify-center mt-14">
      <div className="app-settings">
        <Heading
          leftText={"Your Profile"}
          rightText={"Change Password"}
          leftClick={setProfileActive}
          leftActive={profileActive}
          rightClick={setPasswordActive}
          rightActive={passwordActive}
        />
        {profileActive ? (
          <div>
            <div className="flex items-center gap-5 mt-10">
              <Profile />
              <h2 className="text-[20px] font-[600]">{`${auth.user.firstName} ${auth.user.lastName}`}</h2>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="app-settings__user-form"
            >
              <div className="grid md:grid-cols-2 gap-6 mb-6">
                <div className="app_form">
                  <label htmlFor="firstName">
                    <span className="text-[16px] font-[300]">First name</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    id="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className="input"
                  />

                  {formik.touched.firstName && formik.errors.firstName ? (
                    <span className="app_form-error">
                      {formik.errors.firstName}
                    </span>
                  ) : null}
                </div>
                <div className="app_form">
                  <label htmlFor="lastName">
                    <span className="text-[16px] font-[300]">Last name</span>
                  </label>
                  <input
                    placeholder="Last name"
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className="input"
                  />

                  {formik.touched.lastName && formik.errors.lastName ? (
                    <span className="app_form-error">
                      {formik.errors.lastName}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="mb-10">
                <div className="app_form">
                  <label htmlFor="name">
                    <span className="text-[16px] font-[300]">Email</span>
                  </label>
                  <input
                    disabled
                    value={auth.user.email}
                    type="text"
                    className="block bg-[rgba(18,18,18,0.3)] opacity-30 h-[60px] px-4 rounded-[12px] text-[16px] text-black-300 w-full transition-all duration-200 border-[rgba(18,18,18,0.3)] border-[1.5px]"
                  />
                </div>
              </div>
              <div className="app_form">
                <button
                  type={`${edit ? "submit" : "button"}`}
                  className={`text-white py-3 rounded-[8px] w-full ${
                    updateStatus === NETWORK_STATE.LOADING || !edit
                      ? "bg-[#689DF8] cursor-not-allowed"
                      : "bg-[#045CF4]"
                  }`}
                >
                  {updateStatus === NETWORK_STATE.LOADING
                    ? "Updating..."
                    : "Save changes"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex justify-between my-10">
              <h6 className="text-[20px] text-black">Change password</h6>
            </div>

            <div>
              <form onSubmit={pwd_formik.handleSubmit}>
                <div className="mb-4">
                  <div className="app_form">
                    <label htmlFor="oldPassword">
                      <span className="text-[16px] font-[300]">
                        Old password
                      </span>
                    </label>
                    <div className="app_form-password">
                      <input
                        id="oldPassword"
                        name="oldPassword"
                        placeholder="Your old password"
                        type={showOldPassword ? "text" : "password"}
                        onChange={pwd_formik.handleChange}
                        onBlur={pwd_formik.handleBlur}
                        className={`border-[1px] focus:outline-none signup-input md:w-[50%]
                          ${
                            !pwd_formik.errors.oldPassword &&
                            pwd_formik.touched?.oldPassword
                              ? "focus:border-blue"
                              : ""
                          }
                          ${
                            pwd_formik.errors.oldPassword &&
                            pwd_formik.touched?.oldPassword
                              ? "border-[#C63737] border-[1.5px]"
                              : "border-[rgba(18,18,18,0.3)]"
                          }
                        `}
                      />
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        {!showOldPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                      </button>
                    </div>
                    <div
                      className={`${
                        pwd_formik.touched.oldPassword &&
                        pwd_formik.errors.oldPassword
                          ? "inline-block"
                          : "hidden"
                      } app_form-error mt-2`}
                    >
                      {pwd_formik.touched.oldPassword &&
                        pwd_formik.errors.oldPassword &&
                        pwd_formik.errors.oldPassword}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="app_form">
                    <label htmlFor="name">
                      <span className="text-[16px] font-[300]">
                        New password
                      </span>
                    </label>
                    <div className="app_form-password">
                      <input
                        id="newPassword"
                        name="newPassword"
                        required
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Your new password"
                        onChange={pwd_formik.handleChange}
                        onBlur={pwd_formik.handleBlur}
                        className={`
                          app_form-password confirm-input border-[1.5px]
                          ${
                            !confirmPassword
                              ? "border-[rgba(18,18,18,0.3)]"
                              : ""
                          }
                          ${
                            confirmPassword &&
                            pwd_formik.values.newPassword === confirmPassword
                              ? "border-[#00e1b6]"
                              : "border-[#C63737]"
                          }
                        `}
                      />
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {!showNewPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                      </button>
                    </div>
                  </div>

                  {pwd_formik.touched.newPassword &&
                    pwd_formik.values.newPassword.length > 0 &&
                    !allPassed && (
                      <PasswordCheck
                        password={pwd_formik.values.newPassword}
                        relative
                      />
                    )}
                </div>

                <div className="mb-6">
                  <div className="app_form">
                    <label htmlFor="name">
                      <span className="text-[16px] font-[300]">
                        Confirm new password
                      </span>
                    </label>
                    <div className="app_form-password">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        className={`
                          app_form-password confirm-input border-[1.5px]
                          ${
                            !confirmPassword
                              ? "border-[rgba(18,18,18,0.3)]"
                              : ""
                          }
                          ${
                            confirmPassword &&
                            pwd_formik.values.newPassword === confirmPassword
                              ? "border-[#00e1b6]"
                              : "border-[#C63737]"
                          }
                        `}
                      />
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {!showConfirmPassword ? (
                          <OpenEyeIcon />
                        ) : (
                          <CloseEyeIcon />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="app_form">
                  <button
                    disabled={
                      status === NETWORK_STATE.LOADING ||
                      !/[a-z]/.test(pwd_formik.values.newPassword) ||
                      !/[A-Z]/.test(pwd_formik.values.newPassword) ||
                      !containsSpecialChars(pwd_formik.values.newPassword) ||
                      !/[0-9]/.test(pwd_formik.values.newPassword) ||
                      pwd_formik.values.newPassword !== confirmPassword
                    }
                    type={`${
                      allPassed &&
                      confirmPassword === pwd_formik.values.newPassword
                        ? "submit"
                        : "button"
                    }`}
                    className={`
                      text-white py-3 rounded-[8px] w-full
                      ${
                        updateStatus === NETWORK_STATE.LOADING ||
                        !allPassed ||
                        confirmPassword !== pwd_formik.values.newPassword
                          ? "bg-[#689DF8] cursor-not-allowed"
                          : "bg-[#045CF4]"
                      }`}
                  >
                    {updateStatus === NETWORK_STATE.LOADING
                      ? "Updating..."
                      : "Change Password"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
