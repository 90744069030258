import axios from "../axios";

export const signin = (user) => {
  return axios.post("auth/login/", user);
};

export const updatePassword = (payload) => {
  return axios.put("user/password", payload);
};

export const updateProfile = (payload) => {
  return axios.put("user/profile", payload);
};
