const Heading = ({ leftText, rightText, leftClick, rightClick, leftActive, rightActive }) => {
  return (
    <div className="">
      <div className="relative flex h-[35px] text-[13px] sm:text-[16px]">
        <div
          className="w-[170px] cursor-pointer flex flex-col justify-between"
          onClick={() => {
            leftClick(true);
            rightClick(false);
          }}
        >
          <p className="w-full pl-2 sm:pl-5">{leftText}</p>
          <div className={`${leftActive ? ' bg-[#0060FF]' : 'bg-[#E4E4E4]'} z-[9] h-[2px] w-full`}></div>
        </div>
        <div
          className="w-[170px] cursor-pointer flex flex-col justify-between"
          onClick={() => {
            rightClick(true);
            leftClick(false);
          }}
        >
          <p className="w-full pl-2 sm:pl-5">{rightText}</p>
          <div className={`${rightActive ? ' bg-[#0060FF]' : 'bg-[#E4E4E4]'} z-[9] h-[2px] w-full mt-2`}></div>
        </div>
        <div className="absolute bottom-0 bg-[#E4E4E4] w-full h-[2px] w-full mt-2"></div>
      </div>
    </div>
  )
}

export default Heading;
