import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/layouts/page-layout/PageLayout";
import {
  getApiActiveProjects,
  getApiTerminatedProjects,
} from "../../services/ProjectsService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { NETWORK_STATE, getCurrentWeek, getPreviousWeek } from "../../utils";
import { ReactComponent as UpTrend } from "../../assets/svg/icons/trending_up.svg";
import { ReactComponent as DownTrend } from "../../assets/svg/icons/trending_down.svg";
import { ReactComponent as ActiveProject } from "../../assets/svg/icons/active-admin-node.svg";
import { ReactComponent as TerminatedProject } from "../../assets/svg/icons/terminated-admin-node.svg";
import { usePage } from "../../context/pageContext";

const ApiServices = () => {
  const [projects, setProjects] = useState({ active: [], terminated: [] });
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [networkStatus, setNetworkStatus] = useState({
    active: NETWORK_STATE.LOADING,
    terminated: NETWORK_STATE.LOADING,
    all: NETWORK_STATE.LOADING,
  });
  const [analytics, setAnalytics] = useState({
    cardTwo: {},
    cardOne: {},
    itemsLength: { active: 0, terminated: 0 },
  });
  const [allFilters, setAllFilters] = useState({
    active: {
      searchString: "",
      date: null,
      services: [],
    },
    terminated: {
      searchString: "",
      date: null,
      services: [],
    },
  });
  const navigate = useNavigate();
  const { apiPage, setApiPage } = usePage();
  const auth = useAuth();
  const loadRef = useRef(1);

  // Function to get all api terminated projects
  const fetchTerminatedProjects = async (activeState) => {
    try {
      setNetworkStatus({
        ...networkStatus,
        terminated: NETWORK_STATE.LOADING,
      });
      const response = await getApiTerminatedProjects();
      const allProjects = response?.data?.data?.allServices?.rows;
      const sortedProjects = allProjects.sort((p1, p2) => {
        return p2.updatedAt - p1.updatedAt;
      });
      setProjects({
        ...projects,
        terminated: sortedProjects,
        active: activeState.projects,
      });
      setFilteredProjects({
        ...filteredProjects,
        terminated: sortedProjects,
        active: activeState.filteredProjects,
      });
      setNetworkStatus({
        ...networkStatus,
        terminated: NETWORK_STATE.SUCCESS,
        active: activeState.networkStatus,
      });
    } catch (err) {
      setNetworkStatus({
        ...networkStatus,
        terminated: NETWORK_STATE.ERROR,
        active: activeState.networkStatus,
      });
      console.log(err);
      err?.response?.status === 401 && navigate("/login");
    }
  };

  // Function to get all api active projects
  const fetchProjects = async (state) => {
    let activeState = {
      projects: projects.active,
      filteredProjects: filteredProjects.active,
      networkStatus: networkStatus.active,
    };
    if (state === "all" || state === "active") {
      try {
        setNetworkStatus({
          ...networkStatus,
          active: NETWORK_STATE.LOADING,
        });
        const response = await getApiActiveProjects();
        const allProjects = response?.data?.data?.allServices?.rows;
        const sortedProjects = allProjects.sort((p1, p2) => {
          return p2.createdAt - p1.createdAt;
        });
        setProjects({
          ...projects,
          active: sortedProjects,
        });
        setFilteredProjects({
          ...filteredProjects,
          active: sortedProjects,
        });
        setNetworkStatus({
          ...networkStatus,
          active: NETWORK_STATE.SUCCESS,
        });
        activeState = {
          projects: sortedProjects,
          filteredProjects: sortedProjects,
          networkStatus: NETWORK_STATE.SUCCESS,
        };
      } catch (err) {
        if (err.response?.status === 404) {
          activeState.networkStatus = NETWORK_STATE.SUCCESS;
          setNetworkStatus({
            ...networkStatus,
            active: NETWORK_STATE.SUCCESS,
            terminated: NETWORK_STATE.SUCCESS,
            all: NETWORK_STATE.SUCCESS,
          });
          return;
        } else {
          activeState.networkStatus = NETWORK_STATE.ERROR;
          setNetworkStatus({
            ...networkStatus,
            active: NETWORK_STATE.ERROR,
          });
          err?.response?.status === 401 && navigate("/login");
        }
        console.log(err);
      }
    }
    if (state === "all" || state === "terminated") {
      fetchTerminatedProjects(activeState);
    }
  };

  // Function to calculate analytics
  const getAnalytics = () => {
    // Get the total deployed projects last week and this week
    const lastSunday = getPreviousWeek(1)?.getTime();
    const thisSunday = getCurrentWeek(0)?.getTime();
    const nextSunday = getCurrentWeek(7)?.getTime();

    const lastWeekDeployedProjects = [
      ...projects?.active,
      ...projects.terminated,
    ]?.filter((project) => {
      const dateCreated = new Date(project?.createdAt).getTime();
      return dateCreated >= lastSunday && dateCreated < thisSunday;
    });
    const thisWeekDeployedProjects = [
      ...projects?.active,
      ...projects.terminated,
    ]?.filter((project) => {
      const dateCreated = new Date(project?.createdAt).getTime();
      return dateCreated >= thisSunday && dateCreated < nextSunday;
    });
    const lastWeekTerminatedProjects = projects?.terminated?.filter(
      (project) => {
        const dateTerminated = new Date(project?.updatedAt).getTime();
        return dateTerminated >= lastSunday && dateTerminated < thisSunday;
      }
    );
    const thisWeekTerminatedProjects = projects?.terminated?.filter(
      (project) => {
        const dateTerminated = new Date(project?.updatedAt).getTime();
        return dateTerminated >= thisSunday && dateTerminated < nextSunday;
      }
    );
    const deployedDiff =
      thisWeekDeployedProjects.length - lastWeekDeployedProjects.length;
    const terminatedDiff =
      thisWeekTerminatedProjects.length - lastWeekTerminatedProjects.length;
    setAnalytics({
      ...analytics,
      cardOne: {
        icon: <ActiveProject />,
        title: "Deployed Projects",
        smallText: {
          icon:
            deployedDiff > 0 ? (
              <UpTrend />
            ) : deployedDiff === 0 ? (
              "neutral"
            ) : (
              <DownTrend />
            ),
          iconBg:
            deployedDiff > 0
              ? "bg-[#E3F8EE]"
              : deployedDiff === 0
              ? "bg-[#F8F8F9]"
              : "bg-[#FEF0F0]",
          iconText: `${deployedDiff > 0 ? "+" : ""}${
            deployedDiff !== 0 ? deployedDiff : ""
          }`,
          text:
            deployedDiff > 0
              ? "More than last week"
              : deployedDiff === 0
              ? "Same as last week"
              : "Less than last week",
        },
        count: `${thisWeekDeployedProjects.length > 0 ? "+" : ""}${
          thisWeekDeployedProjects.length
        }`,
      },
      cardTwo: {
        icon: <TerminatedProject />,
        title: "Terminated Projects",
        smallText: {
          icon:
            terminatedDiff > 0 ? (
              <DownTrend />
            ) : terminatedDiff === 0 ? (
              "neutral"
            ) : (
              <UpTrend />
            ),
          iconBg:
            terminatedDiff > 0
              ? "bg-[#FEF0F0]"
              : terminatedDiff === 0
              ? "bg-[#F8F8F9]"
              : "bg-[#E3F8EE]",
          iconText: `${terminatedDiff > 0 ? "+" : ""}${
            terminatedDiff !== 0 ? terminatedDiff : ""
          }`,
          text:
            terminatedDiff > 0
              ? "More than last week"
              : terminatedDiff === 0
              ? "Same as last week"
              : "Less than last week",
        },
        count: `${thisWeekTerminatedProjects.length > 0 ? "+" : ""}${
          thisWeekTerminatedProjects.length
        }`,
      },
      itemsLength: {
        active: projects.active?.length,
        terminated: projects.terminated?.length,
      },
    });
  };

  // Function to Filter projects based on user name or project id
  const filterByNameAndId = (searchString, projects) => {
    if (searchString.length > 0) {
      const f = projects.filter((project) => {
        return (
          project?.projectName?.toLowerCase().includes(searchString) ||
          project?.projectId?.toString().includes(searchString)
        );
      });
      return f;
    } else {
      return projects;
    }
  };

  // Function to Filter users by date created
  const filterByDate = (timeSpan, projects) => {
    if (timeSpan) {
      const todaysDate = new Date();
      const lastXDays = new Date().setDate(todaysDate.getDate() - +timeSpan);
      const projectsByDate = projects.filter((project) => {
        return new Date(project?.createdAt).getTime() >= lastXDays;
      });
      return projectsByDate;
    } else {
      return projects;
    }
  };

  // Filter projects when a filter is added to activeProjects
  useEffect(() => {
    if (projects?.active) {
      let filterResults = [...projects.active];
      if (allFilters.active.date) {
        filterResults = filterByDate(allFilters.active.date, filterResults);
      }
      if (allFilters.active.searchString?.length > 0) {
        filterResults = filterByNameAndId(
          allFilters.active.searchString,
          filterResults
        );
      }
      const sortedResults = filterResults.sort((p1, p2) => {
        return p2.createdAt - p1.createdAt;
      });
      setFilteredProjects({ ...filteredProjects, active: sortedResults });
    }
  }, [
    allFilters.active.searchString,
    allFilters.active.date,
    allFilters.active.services,
  ]);

  // Filter projects when a filter is added to terminated projects
  useEffect(() => {
    if (projects?.terminated) {
      let filterResults = [...projects.terminated];
      if (allFilters.terminated.date) {
        filterResults = filterByDate(allFilters.terminated.date, filterResults);
      }
      if (allFilters.terminated.searchString?.length > 0) {
        filterResults = filterByNameAndId(
          allFilters.terminated.searchString,
          filterResults
        );
      }
      const sortedResults = filterResults.sort((p1, p2) => {
        return p2.createdAt - p1.createdAt;
      });
      setFilteredProjects({ ...filteredProjects, terminated: sortedResults });
    }
  }, [
    allFilters.terminated.searchString,
    allFilters.terminated.date,
    allFilters.terminated.services,
  ]);

  useEffect(() => {
    if (loadRef.current === 1) {
      auth.setHeaderText(["API Services"]);
      fetchProjects("all");
    }
    loadRef.current = 2;
  }, []);

  useEffect(() => {
    getAnalytics();
  }, [projects]);

  useEffect(() => {
    if (apiPage.reload) {
      fetchProjects("all");
      setApiPage({ ...apiPage, reload: false });
    }
  }, [apiPage]);

  return (
    <PageLayout
      pageType={"api"}
      filteredItems={filteredProjects}
      allFilters={allFilters}
      setAllFilters={setAllFilters}
      networkStatus={networkStatus}
      analytics={analytics}
      fetchItems={fetchProjects}
    />
  );
};

export default ApiServices;
