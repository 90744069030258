import { alertError, alertSuccess, NETWORK_STATE } from "../../utils";
import { appConfig } from "../../config";
import { changeApiKey, getApiProject } from "../../services/ProjectsService";
import { networkIcons } from "../../components/layouts/page-layout/PageLayout";
import { ReactComponent as Copy } from "../../assets/svg/icons/content_copy.svg";
import { ReactComponent as Swap } from "../../assets/svg/icons/swap_horiz.svg";
import { useAuth } from "../../context/authContext";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LatencyChart from "../../components/api-services/charts/LatencyChart";
import Loader from "../../assets/img/loader.gif";
import VolumeChart from "../../components/api-services/charts/VolumeChart";

const DisplayEndpoint = ({ projectNetwork, https, websocket, apiKey }) => {
  const [isCopied, setIsCopied] = useState(false);
  const hashKey = "****************";

  const httpsLink = `${projectNetwork?.parameters?.rpc?.https}?api_key=`;
  const wssLink = `${projectNetwork?.parameters?.rpc?.websocket}?api_key=`;

  const handleCopy = (id, text) => {
    navigator.clipboard.writeText(text);
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div className=" flex-grow flex-col gap-[6px]">
      <h5 className="text-16 font-[500]">
        {https ? "HTTPS" : "Websocket"} {projectNetwork?.name}
      </h5>
      <div
        className="
        w-auto h-15 flex justify-between items-center bg-[#EFF5FF] border-[1.5px] px-4 border-[#B1CCFC]
        rounded-[10px]"
      >
        <p className="w-full font-[200] text-[14px] text-ellipsis truncate">
          {https ? `${httpsLink}${hashKey}` : `${wssLink}${hashKey}`}
        </p>
        <div
          className="p-2 cursor-pointer hover:scale-[1.05]"
          onClick={() =>
            handleCopy(
              projectNetwork?.id,
              https ? `${httpsLink}${apiKey}` : `${wssLink}${apiKey}`
            )
          }
        >
          <Copy />
          {isCopied.id === projectNetwork?.id && (
            <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
              Copied!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ApiInsight = () => {
  const auth = useAuth();
  const [project, setProject] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [isCopied, setIsCopied] = useState({});
  const projectId = useLocation().pathname.split("/")[2];
  const [projectNetwork, setProjectNetwork] = useState({});
  const networks = Object.values(appConfig.networks);
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const navigate = useNavigate();

  const getUserProject = async () => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getApiProject(projectId);
      console.log(response);
      const apiProject = response?.data?.data?.apiService;
      setProject(apiProject);
      setApiKey(apiProject?.apiKey);
      auth.setHeaderText([`API Services > ${apiProject?.projectName}`]);
      networks.filter((network) => {
        apiProject?.networkId === network?.id && setProjectNetwork(network);
        return;
      });
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401) {
        alertError("Session Expired. Please Login");
        navigate("/login");
      }
      alertError(err?.response?.data.message);
      setProject([]);
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  useEffect(() => {
    getUserProject();
  }, []);

  const changeUserApiKey = async () => {
    setApiKey("");
    try {
      const response = await changeApiKey(projectId, {
        networkId: projectNetwork.id,
      });
      alertSuccess(response.data.message);
      setApiKey(response.data.data.apiServiceKey);
    } catch (err) {
      setApiKey(project.apiKey);
      alertError(err?.message);
      console.log(err?.message);
    }
  };

  const handleCopy = (id, text) => {
    navigator.clipboard.writeText(text);
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div>
      {status === NETWORK_STATE.LOADING ? (
        <div className="w-full h-[100vh] flex items-center justify-center">
          <img src={Loader} alt="Loading..." className="self-center py-20" />
        </div>
      ) : status === NETWORK_STATE.SUCCESS ? (
        <div className="project-insight">
          <div className="app-home__heading">
            <h2>Focused Network</h2>
          </div>
          <div className={`project-insight__hero`}>
            <div className="project-insight__hero-card">
              {networkIcons[projectNetwork.name]}
              <h2>{projectNetwork.name}</h2>
              <p>
                Responses <span>(24h)</span>
              </p>
            </div>

            <div className="w-[calc(100%-374px)] flex flex-col gap-6">
              <DisplayEndpoint
                projectNetwork={projectNetwork}
                https
                apiKey={apiKey}
              />
              <DisplayEndpoint
                projectNetwork={projectNetwork}
                wss
                apiKey={apiKey}
              />
            </div>
          </div>
          <div className="project-insight__api">
            <p className="project-insight__api-title">API key</p>
            <div className="w-full flex flex-col md:flex-row justify-between gap-6 items-end">
              <div className="w-[calc(100%-220px)]">
                <p className="text-16 font-[500] text-[#3A3C3F]">API Key</p>
                <div
                  className="w-full mt-[6px] h-15 flex justify-between items-center bg-[#EFF5FF] border-[1.5px] px-4 border-[#B1CCFC]
                  rounded-[10px]"
                >
                  {apiKey ? (
                    "****************"
                  ) : (
                    <img src={Loader} alt="..." className="self-center" />
                  )}
                  <button
                    type="button"
                    onClick={() => handleCopy("apikey", apiKey)}
                  >
                    <Copy />
                    {isCopied.id === "apikey" && (
                      <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
                        Copied!
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <button
                disabled={!apiKey}
                type="button"
                className="standard-btn-1 w-[196px]"
                onClick={changeUserApiKey}
              >
                <Swap className="" />
                {apiKey ? "Change key" : "...changing"}
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="project-insight__graph">
              <div className="w-full flex items-center justify-between">
                <p className="project-insight__graph-text">
                  Response Volume <span>(Past24 Hours)</span>
                </p>
              </div>
              {projectNetwork ? (
                <VolumeChart networkId={projectNetwork?.id} />
              ) : (
                <img
                  src={Loader}
                  alt="Loading..."
                  className="self-center py-20"
                />
              )}
            </div>
            <div className="project-insight__graph">
              <div className="w-full flex items-center justify-between">
                <p className="project-insight__graph-text">
                  Latency <span>(Past24 Hours)</span>
                </p>
              </div>
              {projectNetwork ? (
                <LatencyChart networkId={projectNetwork?.id} />
              ) : (
                <img
                  src={Loader}
                  alt="Loading..."
                  className="self-center py-20"
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="mx-auto mt-[10%] underline text-blue"
          onClick={getUserProject}
        >
          Retry
        </div>
      )}
    </div>
  );
};

export default ApiInsight;
