import { createContext, useContext, useEffect, useRef, useState } from "react";
import { socket } from "../socket";
import { alertError } from "../utils";
const LiveContext = createContext();

export const LiveProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [events, setEvents] = useState([]);
  const [lastEvent, setLastEvent] = useState({});
  const [reloadParachainList, setReloadParachainList] = useState(false);
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const errorDisplayCount = useRef(0);

  useEffect(() => {
    function retrySocket() {
      setTimeout(() => {
        socket.connect();
      }, 2000);
    }

    const reloadPending = () => {
      setReloadNotifications(true);
    };

    function onConnect() {
      setIsConnected(true);
      console.log("Connected to websocket via socket.io!");
    }

    function onDisconnect() {
      setIsConnected(false);
      console.log("Disconnected socket gracefully!");
      retrySocket();
    }

    function onCreateParachainEvent(value) {
      setEvents((previous) => [...previous, value]);
      setLastEvent(value);
      setReloadParachainList(true);
      setReloadNotifications(true);
    }

    function onDeleteParachainEvent(value) {
      setEvents((previous) => [...previous, value]);
      setLastEvent(value);
      setReloadParachainList(true);
      setReloadNotifications(true);
    }

    function onCreateError(event) {
      setTimeout(() => {
        if (errorDisplayCount.current === 0) {
          if (event.event === "alreadyExist")
            alertError("A project with that name exists!");
          else if (event.event === "serverError")
            alertError("Something went wrong! Please retry.");
          else alertError("Something went wrong! Please retry.");
          reloadPending();
          console.log(event);
          errorDisplayCount.current = errorDisplayCount.current + 1;
          setTimeout(() => {
            errorDisplayCount.current = 0;
          }, 5000);
        }
      }, 2000);
    }

    function onDeleteError(event) {
      setTimeout(() => {
        if (errorDisplayCount.current === 0) {
          if (event.event === "notFound") alertError("Project doesn't exist.");
          else if (event.event === "serverError")
            alertError("Something went wrong! Please retry.");
          else alertError("Something went wrong! Please retry.");
          reloadPending();
          console.log(event);
          errorDisplayCount.current = errorDisplayCount.current + 1;
          setTimeout(() => {
            errorDisplayCount.current = 0;
          }, 5000);
        }
      }, 2000);
    }

    function onInfraError(event) {
      console.log(event);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("createParachain", onCreateParachainEvent);
    socket.on("deleteParachain", onDeleteParachainEvent);
    socket.on("createError", onCreateError);
    socket.on("deleteError", onDeleteError);
    socket.on("infraError", onInfraError);
    socket.on("connect_error", () => {
      console.log("Error connecting to websocket! Retrying...");
      retrySocket();
    });
  }, []);

  return (
    <LiveContext.Provider
      value={{
        isConnected,
        events,
        lastEvent,
        setLastEvent,
        reloadParachainList,
        reloadNotifications,
        setReloadParachainList,
        setReloadNotifications,
      }}
    >
      {children}
    </LiveContext.Provider>
  );
};

export const useLiveUpdates = () => useContext(LiveContext);
