import { useHeader } from "../../../context/headerContext";

export const InsightContainer = ({ children, hide }) => {
  const { minimizeNav } = useHeader();

  return (
    <div
      className={`insight-container ${
        minimizeNav ? "h-[200px] 2xl:h-[220px]" : "h-[180px] 2xl:h-[200px]"
      } ${hide ? "hidden" : "flex flex-col"}`}
    >
      {children}
    </div>
  );
};

export const FailedNetwork = ({ fetchItems }) => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <p className="text-[13px]">Failed to load</p>
      <p
        className="underline text-blue text-[13px] cursor-pointer"
        onClick={() => fetchItems()}
      >
        Retry
      </p>
    </div>
  );
};
