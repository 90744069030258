import ReactECharts from "echarts-for-react";
import { getDateTime } from "../../../utils";

export const nodeColors = [
  "#045CF4",
  "#F49600",
  "#9C04F4",
  "#00A1A1",
  "#6AA0FF",
];
const white = "#FFFFFF";
const lightGrey = "#00000033";
const black = "#000000";

const ParachainCPUChart = () => {
  const startTimeOfChart = new Date(new Date().getTime() - 23 * 60 * 60 * 1000);
  startTimeOfChart.setMinutes(0);
  startTimeOfChart.setSeconds(0);
  startTimeOfChart.setMilliseconds(0);

  const endTimeOfChart = new Date();
  endTimeOfChart.setMinutes(0);
  endTimeOfChart.setSeconds(0);
  endTimeOfChart.setMilliseconds(0);

  const graphLabels = {};
  const xAxisLabels = [];
  let i = 0;

  while (i < 24) {
    const currDate = new Date(startTimeOfChart.getTime());
    currDate.setHours(currDate.getHours() + i);
    let graphTime = getDateTime(currDate).time;
    const key = String(currDate.getTime()).slice(0, 10);
    graphLabels[key] = graphTime;
    xAxisLabels.push(graphTime);
    i++;
  }

  const option = {
    title: {
      text: "CPU 4 cores",
      textStyle: {
        color: black,
        fontSize: 20,
      },
    },
    legend: {
      show: false,
      type: "scroll",
      top: 10,
      data: ["Node 1", "Node 2"],
      orient: "horizontal",
      icon: "roundRect",
      itemGap: 30,
    },
    color: nodeColors,
    xAxis: {
      type: "category",
      data: xAxisLabels,
      axisTick: {
        alignWithLabel: true,
        show: false,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: lightGrey,
          width: 0.5,
          opacity: "0.5",
        },
      },
      axisLabel: {
        color: black,
      },
      nameTextStyle: {
        color: black,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
    },
    grid: {
      borderColor: lightGrey,
      left: "20px",
      right: "20px",
    },
    series: [
      {
        name: "Node 1",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        type: "line",
        smooth: true,
        lineStyle: {
          width: 4,
          /* color: colors[0], */
        },
        /* tooltip: {
          backgroundColor: colors[0],
          borderColor: colors[0],
          textStyle: {
            color: white,
            textBorderColor: white,
          },
        }, */
      },
    ],
    markpoint: {
      symbol: "none",
    },
    tooltip: {
      trigger: "item",
      position: "top",
      backgroundColor: white,
    },
  };

  return (
    <div className="w-full">
      <ReactECharts style={{ height: "500px" }} option={option} />
    </div>
  );
};

export default ParachainCPUChart;
