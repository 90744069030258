import ReactECharts from "echarts-for-react";
import { getDateTime } from "../../../utils";
import { useEffect, useState } from "react";
import { getGraphData } from "../../../services/NetworkService";

export const nodeColors = [
  "#045CF4",
  "#F49600",
  "#9C04F4",
  "#00A1A1",
  "#6AA0FF",
];
const white = "#FFFFFF";
const lightGrey = "#EFEFEF";
const axisColor = "#9B9B9B";
const black = "#1A1B1E";

const LatencyChart = ({ networkId }) => {
  const defaultData = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  const [data, setData] = useState(defaultData);
  const networkMapping = {
    1: "blockops-polkadot",
    2: "blockops-kusama",
    3: "blockops-flow",
    4: "blockops-composable",
    5: "blockops-moonbeam",
    6: "blockops-moonriver",
  };
  const startTimeOfChart = new Date(new Date().getTime() - 23 * 60 * 60 * 1000);
  startTimeOfChart.setMinutes(0);
  startTimeOfChart.setSeconds(0);
  startTimeOfChart.setMilliseconds(0);

  const endTimeOfChart = new Date();
  endTimeOfChart.setMinutes(0);
  endTimeOfChart.setSeconds(0);
  endTimeOfChart.setMilliseconds(0);

  const graphLabels = {};
  const xAxisLabels = [];
  let i = 0;

  while (i < 24) {
    const currDate = new Date(startTimeOfChart.getTime());
    currDate.setHours(currDate.getHours() + i);
    let graphTime = getDateTime(currDate).time;
    const key = String(currDate.getTime()).slice(0, 10);
    graphLabels[key] = graphTime;
    xAxisLabels.push(graphTime);
    i++;
  }

  const option = {
    title: {
      show: false,
      text: "",
      textStyle: {
        color: black,
        fontSize: 20,
      },
    },
    legend: {
      show: false,
      type: "scroll",
      top: 10,
      data: ["lat in sec", "Node 2"],
      orient: "horizontal",
      icon: "roundRect",
      itemGap: 30,
    },
    color: nodeColors,
    xAxis: {
      type: "category",
      data: xAxisLabels,
      axisTick: {
        alignWithLabel: true,
        show: false,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: lightGrey,
          width: 0.5,
          opacity: "0.5",
        },
      },
      axisLabel: {
        color: axisColor,
        margin: 20,
      },
      nameTextStyle: {
        color: axisColor,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: true,
      axisLabel: {
        show: true,
        inside: false,
        rotate: 0,
        margin: 20,
        padding: [0, 0, 0, 0],
        width: 100,
        backgroundColor: "",
        color: axisColor,
        align: "left",
      },
    },
    grid: {
      borderColor: lightGrey,
      left: "20px",
      right: "20px",
    },
    series: [
      {
        name: "Latency (ms)",
        data: data,
        type: "line",
        smooth: true,
        lineStyle: {
          width: 4,
        },
      },
    ],
    markpoint: {
      symbol: "none",
    },
    tooltip: {
      trigger: "item",
      position: "top",
      backgroundColor: white,
    },
  };

  // Get graph data
  const fetchGraphData = async (endpoint) => {
    let response;
    if (networkId === 1 || networkId === 2) {
      try {
        response = await getGraphData(endpoint);
      } catch {
        response = null;
      }
    } else {
      response = null;
    }

    if (response) {
      const matrix = response?.data?.data?.result[0]?.values || null;
      const newMatrix = {};
      const liveData = [...defaultData];
      matrix.forEach((point, index) => {
        if (index < matrix.length) {
          newMatrix[point[0]] = point[1];
          liveData[liveData.length - (index + 1)] =
            matrix[matrix.length - (index + 1)][1];
        }
      });
      setData(liveData);
    }
  };

  useEffect(() => {
    const dataEndpoint = `${
      process.env.REACT_APP_DATAPOINT_URL
    }/api/v1/query_range?query=sum(nginx_ingress_controller_ingress_upstream_latency_seconds_sum 
      {ingress="blockops-polkadot-ingress"})&start=${String(
        startTimeOfChart.getTime()
      ).slice(0, 10)}&end=${String(endTimeOfChart.getTime()).slice(
      0,
      10
    )}&step=1h`;

    fetchGraphData(dataEndpoint);
  }, []);

  return (
    <div className="w-full">
      <ReactECharts style={{ height: "500px" }} option={option} />
    </div>
  );
};

export default LatencyChart;
