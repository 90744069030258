import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/img/app-logo.png";
import Menu from "../../assets/img/menu.png";
import { ReactComponent as Dashboard } from "../../assets/svg/nav/dashboard_fill.svg";
import { ReactComponent as Settings } from "../../assets/svg/nav/settings_fill.svg";
import { ReactComponent as Soon } from "../../assets/svg/nav/soon.svg";
import { ReactComponent as Documentation } from "../../assets/svg/nav/docs.svg";
import { ReactComponent as Service } from "../../assets/svg/nav/service_fill.svg";
import { ReactComponent as Support } from "../../assets/svg/nav/help_fill.svg";
import { ReactComponent as Logout } from "../../assets/svg/nav/logout.svg";
import { ReactComponent as ParachainIcon } from "../../assets/svg/nav/dedicated-sidebar.svg";
import { ReactComponent as DedicatedIcon } from "../../assets/svg/nav/dedicated-void.svg";
import { useAuth } from "../../context/authContext";

export const Fullnav = ({ resizeNav, renderCount, mobile }) => {
  const [userSettings] = useState(
    JSON.parse(localStorage.getItem("userSettings"))
  );
  const { logout } = useAuth();

  return (
    <nav
      className={`
      side-nav
      ${mobile ? "mobile-nav" : ""}
      ${
        userSettings?.dashboard?.navBar.minimize && renderCount?.current === 1
          ? "hidden"
          : "flex"
      }`}
    >
      <div className="side-nav__header">
        <Link to="/endpoints">
          <img src={Logo} alt="logo" className="min-hide-logo w-[150px]" />
        </Link>
        <button
          className="navcontroller"
          onClick={() => {
            resizeNav();
          }}
        >
          <img src={Menu} alt="" width={27} />
        </button>
      </div>

      <div className="flex fle-col items-end h-[60px] text-white opacity-50 text-sm pl-10 uppercase">
        <p className="min-hide">Main menu</p>
      </div>

      <div className="side-nav__cont">
        <div className="side-nav__cont-top">
          <NavLink className="nav_link " to="/users" onClick={() => {}}>
            <Dashboard />
            <span className="min-hide">User</span>
          </NavLink>

          <NavLink className="nav_link " to="/api-services" onClick={() => {}}>
            <Service />
            <span className="min-hide">API Services</span>
          </NavLink>

          <NavLink
            className="nav_link "
            to="/dedicated-nodes"
            onClick={() => {}}
          >
            <DedicatedIcon className="w-5" />
            <span className="min-hide">Dedicated Nodes</span>
          </NavLink>

          <NavLink
            className="nav_link "
            to="/parachain-nodes"
            onClick={() => {}}
          >
            <ParachainIcon className="w-5" />
            <span className="min-hide">Parachain Nodes</span>
          </NavLink>

          {/* <div className="group nav_link relative">
          <DedicatedIcon className="w-5" />
            <p className="min-hide text-[#9C9DA7]">Dedicated Nodes</p>
          </div> */}

          {/* <div className="group nav_link cursor-not-allowed relative">
            <Soon />
            <p className="min-hide text-[#9C9DA7]">Validator Nodes</p>
          </div>
          <div className="group nav_link cursor-not-allowed relative">
            <Soon />
            <p className="min-hide text-[#9C9DA7]">Data Services</p>
          </div> */}
          <NavLink className="nav_link" to="/settings" onClick={() => {}}>
            <Settings />
            <span className="min-hide">Settings</span>
          </NavLink>
        </div>

        <div className="side-nav__cont-bottom">
          <div className="side-nav__cont-bottom-links">
            <div className="nav_link cursor-pointer" onClick={() => logout()}>
              <Logout />
              <span className="min-hide">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
