import axios from "axios";

export const getNetworkStatus = async (networkId) => {
  const networkMapping = {
    1: "blockops-polkadot",
    2: "blockops-kusama",
    3: "blockops-flow",
    4: "blockops-composable",
    5: "blockops-moonbeam",
    6: "blockops-moonriver",
  };

  const url = `${process.env.REACT_APP_DATAPOINT_URL}/api/v1/query?query=envoy_cluster_membership_total{envoy_cluster_name="${networkMapping[networkId]}"} - envoy_cluster_membership_healthy{envoy_cluster_name="${networkMapping[networkId]}"} == bool 0`;
  const config = {
    method: "get",
    url,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_NETSTATUS_AUTH}`,
    },
  };

  const response = await axios(config);
  return response;
};

export const getGraphData = async (url) => {
  const config = {
    method: "get",
    url,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_NETSTATUS_AUTH}`,
    },
  };

  const response = await axios(config);
  return response;
};
