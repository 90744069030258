import Modal from "react-modal";
import { ReactComponent as Delete } from "../../../assets/svg/icons/bin.svg";
import CancelModal from "../../../assets/img/cancel-modal.png";
import { useState } from "react";
import { useHeader } from "../../../context/headerContext";
import { NETWORK_STATE, alertError, alertSuccess } from "../../../utils";
import {
  deleteApiProject,
  terminateDedicatedProject,
  terminateParachainProject,
} from "../../../services/ProjectsService";
import { useNavigate } from "react-router-dom";
import { usePage } from "../../../context/pageContext";
import { useLiveUpdates } from "../../../context/liveUpdates";
//import { useAuth } from "../../context/authContext";

// Styles for modal
const customStyles = {
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    opacity: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
};

export const TerminateModal = ({
  terminateModalIsOpen,
  setTerminateModalIsOpen,
  project,
  projectType,
}) => {
  const { minimizeNav } = useHeader();
  const [terminateConfirmation, setTerminateConfirmation] = useState("");
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const { apiPage, setApiPage, singleNodePage, setSingleNodePage } = usePage();
  const { setReloadNotifications } = useLiveUpdates();
  const navigate = useNavigate();
  let terminateFunction = () => {};

  if (projectType === "api") {
    terminateFunction = async () => {
      try {
        setStatus(NETWORK_STATE.LOADING);
        const response = await deleteApiProject(project?.id, project?.orgId);
        setStatus(NETWORK_STATE.SUCCESS);
        closeModal();
        setApiPage({ ...apiPage, reload: true });
        alertSuccess(response.data?.message);
      } catch (err) {
        console.log(err);
        err?.response?.status === 401 && navigate("/login");
        setStatus(NETWORK_STATE.ERROR);
        closeModal();
        alertError(err.response?.data?.message);
      }
    };
  } else if (projectType === "single-node") {
    terminateFunction = async () => {
      try {
        setStatus(NETWORK_STATE.LOADING);
        const response = await terminateDedicatedProject(project?.id);
        setStatus(NETWORK_STATE.SUCCESS);
        closeModal();
        navigate("/dedicated-nodes");
        setSingleNodePage({ ...singleNodePage, reload: true });
        alertSuccess(response.data?.message);
      } catch (err) {
        console.log(err);
        err?.response?.status === 401 && navigate("/login");
        setStatus(NETWORK_STATE.ERROR);
        closeModal();
        alertError(err.response?.data?.message);
      }
    };
  } else {
    terminateFunction = async () => {
      try {
        setStatus(NETWORK_STATE.LOADING);
        const response = terminateParachainProject(
          project?.id,
          project?.projectName
        );
        setStatus(NETWORK_STATE.SUCCESS);
        closeModal();
        navigate("/parachain-nodes");
      } catch (err) {
        console.log(err);
        err?.response?.status === 401 && navigate("/login");
        setStatus(NETWORK_STATE.ERROR);
        closeModal();
        alertError(err.response?.data?.message);
      }
      setTimeout(() => {
        setReloadNotifications(true);
      }, 1000);
    };
  }

  const closeModal = () => {
    setTerminateConfirmation("");
    setTerminateModalIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={terminateModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Terminate Project Modal"
        overlayClassName={`
    left-0
    ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white w-full sm:w-[350px]"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="w-full flex flex-col px-5 py-7 gap-5">
          <div className="w-full flex justify-end items-center">
            <button onClick={closeModal}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <div className="h-[130px] w-[130px] flex items-center justify-center self-center mb-5 bg-[#D9E7FF] rounded-full">
            <Delete className="scale-[0.9]" />
          </div>
          <div className="self-center">
            <h2 className="text-[18px] leading-[15px] text-center font-[600]">
              Are you sure?
            </h2>
            <p className="mt-1 text-[12px] text-[#1F1F1F99] text-center">
              Please input "terminate" if you are sure you want to delete this
              project.
            </p>
          </div>
          <div className="w-full">
            <input
              type={"text"}
              onChange={(e) => setTerminateConfirmation(e.target.value)}
              className="w-full block h-[50px] px-4 rounded-[8px] text-[16px] border-[1px] border-[#1212124d] outline-none"
            />
            <button
              type="button"
              disabled={
                terminateConfirmation.toLowerCase() !== "terminate" ||
                status === NETWORK_STATE.LOADING
              }
              onClick={terminateFunction}
              className="app_button full mt-5"
            >
              {status === NETWORK_STATE.LOADING
                ? "Terminating"
                : "Terminate project"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
