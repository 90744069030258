import React, { useEffect, useState } from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/authContext";
import { HeaderProvider } from "./context/headerContext";
import { Dashboard } from "./components/layouts/Dashboard";
import { Auth } from "./components/layouts/Auth";
import { ProjectProvider } from "./context/projectContext";
import { ManualProvider } from "./context/manualContext";
import NotFound from "./pages/others/notFound";
import Users from "./pages/user/Users";
import AppLogin from "./pages/auth/AppLogin";
import ApiServices from "./pages/api-services/ApiServices";
import { NetworkProvider } from "./context/networkContext";
import DedicatedNodes from "./pages/dedicated-nodes/DedicatedNodes";
import DedicatedProjectInsight from "./pages/dedicated-nodes/NodeInsight";
import ParachainNodes from "./pages/parachain-nodes/ParachainNodes";
import ApiInsight from "./pages/api-services/ApiInsight";
import { PageProvider } from "./context/pageContext";
import Settings from "./pages/settings";
import { LiveProvider } from "./context/liveUpdates";
import ParachainProjectInsight from "./pages/parachain-nodes/ParachainNodeInsignt";

const AppWrapper = () => {
  const auth = useAuth();
  const [fetchingUser, setFetchingUser] = useState(true);

  useEffect(() => {
    try {
      const userObj = JSON.parse(localStorage.getItem("user"));
      if (userObj) {
        let expiryDate = new Date(userObj.auth.expiryDate);
        let todaysDate = new Date();
        if (todaysDate > expiryDate) {
          auth.logout();
        } else {
          auth.initUser(userObj);
        }
      }
      setFetchingUser(false);
    } catch (err) {}
  }, []);

  return (
    <div className="">
      {fetchingUser ? (
        <h2>Fetching user</h2>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <Auth>
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path="/login"
            element={
              <Auth>
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path="/users"
            element={
              <Dashboard>
                <Users />
              </Dashboard>
            }
          ></Route>

          <Route
            path="/api-services"
            element={
              <ManualProvider>
                <Dashboard>
                  <ApiServices />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/api-services/:projectId"
            element={
              <ManualProvider>
                <Dashboard>
                  <ApiInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/dedicated-nodes"
            element={
              <ManualProvider>
                <Dashboard>
                  <DedicatedNodes />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/parachain-nodes"
            element={
              <ManualProvider>
                <Dashboard>
                  <ParachainNodes />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/dedicated-nodes/:projectId"
            element={
              <ManualProvider>
                <Dashboard>
                  <DedicatedProjectInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/parachain-nodes/:projectId"
            element={
              <ManualProvider>
                <Dashboard>
                  <ParachainProjectInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <Dashboard>
                <Settings />
              </Dashboard>
            }
          ></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      )}
    </div>
  );
};
function App() {
  return (
    <LiveProvider>
      <HeaderProvider>
        <AuthProvider>
          <ProjectProvider>
            <ManualProvider>
              <NetworkProvider>
                <PageProvider>
                  <AppWrapper />
                  <Toaster position="top-right" />
                </PageProvider>
              </NetworkProvider>
            </ManualProvider>
          </ProjectProvider>
        </AuthProvider>
      </HeaderProvider>
    </LiveProvider>
  );
}

export default App;
