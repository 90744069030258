import { createContext, useContext, useState } from "react";

const PageContext = createContext();

export const PageProvider = ({ children }) => {
  const [apiPage, setApiPage] = useState({ reload: false });
  const [singleNodePage, setSingleNodePage] = useState({ reload: false });
  return (
    <PageContext.Provider
      value={{ apiPage, setApiPage, singleNodePage, setSingleNodePage }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePage = () => useContext(PageContext);
