/* import { ReactComponent as Options } from "../../assets/svg/icons/options.svg";
import { ReactComponent as Open } from "../../assets/svg/icons/file_open.svg";
import { ReactComponent as MailIcon } from "../../assets/svg/icons/mail-icon.svg";
import { ReactComponent as ProjectIcon } from "../../assets/svg/icons/project-icon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { capitalizeWord, getDateTime } from "../../utils";
import { networkIcons } from "../layouts/page-layout/PageLayout";

const StatusDiv = ({ status }) => {
  const colorMapping = {
    active: {
      style: "bg-[#ECFBEF] text-[#2C9067] border-[#2C9067]",
      bg: "bg-[#2C9067]",
    },
    pause: {
      style: "bg-[#FFEADB] text-[#C06C30] border-[#C06C30]",
      bg: "bg-[#C06C30]",
    },
    terminate: {
      style: "bg-[#FFDBDB] text-[#C03030] border-[#C03030]",
      bg: "bg-[#C03030]",
    },
  };
  return (
    <div
      className={`${colorMapping[status].style} border-[0.5px] rounded-[85px] px-2 py-[2px] flex items-center gap-1 capitalize font-[400]`}
    >
      <div className={`w-2 h-2 rounded-full ${colorMapping[status].bg}`}></div>
      <p>
        {status === "pause" || status === "terminate" ? `${status}d` : status}
      </p>
    </div>
  );
};

const NodeRow = ({ node, header, selectedOption, setSelectedOption }) => {
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(getDateTime(node?.createdAt));
  const [userFirstService, setUserFirstService] = useState(null);

  const colorMapping = {
    active: {
      style: "bg-[#ECFBEF] text-[#2C9067] border-[#2C9067]",
      bg: "bg-[#2C9067]",
    },
    pause: {
      style: "bg-[#FFEADB] text-[#C06C30] border-[#C06C30]",
      bg: "bg-[#C06C30]",
    },
    terminate: {
      style: "bg-[#FFDBDB] text-[#C03030] border-[#C03030]",
      bg: "bg-[#C03030]",
    },
  };

  useEffect(() => {
    setDateTime(getDateTime(node?.createdAt));
  }, [node]);

  const handleOptionClick = () => {
    if (selectedOption !== node?.id) {
      setSelectedOption(node?.id);
    } else {
      setSelectedOption(null);
    }
  };

  return (
    <div
      className={`
        ${
          header
            ? "bg-[#F1F6FF] mt-5 cursor-default text-[#13141B] text-[12px]"
            : "bg-white border-b border-[#EBEBEB] cursor-pointer text-[14px]"
        }
        h-[60px] w-full flex items-center px-3 md:px-8`}
      onClick={() => navigate(`/dedicated-nodes/${node?.id}`)}
    >
      <div className="w-full h-full flex items-center">
        <div className="w-[5%] md:w-[3%]">
          <div
            className={`w-2 h-2 rounded-full ${
              node?.status === "active" && "bg-[#2C9067]"
            } ${node?.status === "pause" && "bg-[#C06C30]"}
            ${node?.status === "terminate" && "bg-[#C03030]"}`}
          ></div>
        </div>
        <div className="w-[53%] md:w-[40%] lg:w-[35%]">
          <div className="w-full h-full flex items-center gap-3">
            <div>{!header && <MailIcon className="scale-[0.9]" />}</div>
            <p className="truncate pr-3">
              {header ? "User Email" : `${node.organization?.email}`}
            </p>
          </div>
        </div>
        <div className="w-[38%] md:w-[35%] lg:w-[30%]">
          <div className="w-full h-full flex items-center gap-3">
            {header ? (
              <></>
            ) : (
              <div className="w-8">
                <ProjectIcon className="scale-[0.9]" />
              </div>
            )}
            <p className="truncate">
              {header ? "Project Name" : `${node.projectName}`}
            </p>
          </div>
        </div>
        <div className="hidden md:flex md:w-[20%] flex items-center gap-3">
          {header ? (
            <div className=""></div>
          ) : (
            <div className="w-10 scale-[0.4]">
              {node && networkIcons[capitalizeWord(node.networkName)]}
            </div>
          )}

          <p>{header ? "Network" : capitalizeWord(node.networkName)}</p>
        </div>
        <div className="hidden lg:flex w-[15%]">
          {header ? (
            "Date Deployed"
          ) : (
            <p>
              {dateTime.date}{" "}
              <span className="text-[11px] text-[#797979]">
                {dateTime.time}
              </span>
            </p>
          )}
        </div>
      </div>
      <div className="w-5 md:w-10 relative z-[999]">
        <button
          className={`${
            header ? "hidden" : "w-10 flex md:justify-end cursor-default"
          }`}
          onClick={handleOptionClick}
          onBlur={() => setSelectedOption(null)}
        >
          <Options />
          {selectedOption === node?.id && (
            <div className="absolute right-10 top-0 w-[200px] text-[13px] font-[200] text-[#717171] flex flex-col bg-white p-5 pb-0 rounded-[8px] shadow-md">
              <div
                onClick={() => navigate(`/dedicated-nodes/${node?.id}`)}
                className="flex items-center gap-2 py-4 pl-5 border-b-[0.5px] border-b-[#D8D8D8] cursor-pointer"
              >
                <Open className="scale-90" />
                <p>Open</p>
              </div>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default NodeRow;
 */

import { appConfig } from "../../config";
import { ReactComponent as DedicatedProject } from "../../assets/svg/icons/dedicated-project.svg";
import { ReactComponent as Options } from "../../assets/svg/icons/options.svg";
import { ReactComponent as Delete } from "../../assets/svg/icons/terminate.svg";
import { networkIcons } from "../layouts/page-layout/PageLayout";
import { useEffect, useState } from "react";
import { capitalizeString, getDateTime } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Open } from "../../assets/svg/icons/file_open.svg";
import { TerminateModal } from "../layouts/page-layout/Modals";
//import { TerminateModal } from "../layouts/page-layout/Modals";

const StatusDiv = ({ status }) => {
  const colorMapping = {
    active: {
      style: "bg-[#ECFBEF] text-[#2C9067] border-[#2C9067]",
      bg: "bg-[#2C9067]",
    },
    pause: {
      style: "bg-[#FFEADB] text-[#C06C30] border-[#C06C30]",
      bg: "bg-[#C06C30]",
    },
    terminate: {
      style: "bg-[#FFDBDB] text-[#C03030] border-[#C03030]",
      bg: "bg-[#C03030]",
    },
  };
  return (
    <div
      className={`${colorMapping[status].style} border-[0.5px] rounded-[85px] px-2 py-[2px] flex items-center gap-1 capitalize font-[400]`}
    >
      <div className={`w-2 h-2 rounded-full ${colorMapping[status].bg}`}></div>
      <p>
        {status === "pause" || status === "terminate" ? `${status}d` : status}
      </p>
    </div>
  );
};

const NodeRow = ({ project, header, activeProject, pageType }) => {
  const navigate = useNavigate();
  const [cloudProvider, setCloudProvider] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const [liveProjectStatus, setLiveProjectStatus] = useState(project?.status);

  useEffect(() => {
    if (project) {
      const cProvider = appConfig.cloudProviders.filter((provider) => {
        return capitalizeString(provider.label) === project?.cloudProvider;
      });
      setLiveProjectStatus(project?.status);
      setCloudProvider(cProvider[0]);
    }
  }, [project]);

  useEffect(() => {
    setLiveProjectStatus(project?.status);
  }, [terminateModalIsOpen]);

  return (
    <div className="w-full">
      {header ? (
        <div className="bg-[#F1F6FF] mt-5 cursor-default h-[60px] w-full flex items-center text-[14px] px-3 md:px-8">
          <div className="w-[55%] md:w-[35%] lg:w-[20%] h-full flex items-center gap-2">
            Project Name
          </div>
          <div className="hidden md:w-[30%] lg:w-[20%] h-full md:flex items-center">
            <p className="ml-4">Network</p>
          </div>
          <div className="hidden lg:w-[25%] h-full lg:flex items-center gap-1">
            Cloud Provider
          </div>
          <div className="w-[40%] md:w-[30%] lg:w-[15%] h-full flex items-center">
            Status
          </div>
          <div className="hidden w-[18%] h-full lg:flex items-center">
            Date Terminated
          </div>
        </div>
      ) : (
        <div
          className="bg-white border-b border-[#EBEBEB] cursor-pointer h-[60px] w-full flex items-center text-[14px] px-3 md:px-8"
          onClick={() => {
            if (activeProject) {
              pageType === "single-node" &&
                navigate(`/dedicated-nodes/${project?.id}`);
              pageType === "para-node" &&
                navigate(`/parachain-nodes/${project?.id}`);
            }
          }}
        >
          <div className="w-[55%] md:w-[35%] lg:w-[20%] h-full flex items-center gap-2">
            <DedicatedProject />
            <p className="truncate overflow-ellipsis pr-2">{`${project?.projectName}`}</p>
          </div>
          <div className="hidden md:w-[30%] lg:w-[20%] h-full md:flex items-center">
            <div className="scale-[0.5]">
              <p>{networkIcons[capitalizeString(project?.networkName)]}</p>
            </div>
            <p className="-ml-2 truncate overflow-ellipsis pr-2">
              {capitalizeString(project?.networkName)}
            </p>
          </div>
          <div className="hidden lg:w-[25%] h-full lg:flex items-center gap-1">
            {cloudProvider && (
              <img src={cloudProvider.icon} alt="" width={25} />
            )}
            <p className="truncate overflow-ellipsis pr-2">
              {cloudProvider.label}
            </p>
          </div>
          <div className="w-[40%] md:w-[30%] lg:w-[15%] h-full flex items-center">
            {liveProjectStatus && <StatusDiv status={liveProjectStatus} />}
          </div>
          <div className="hidden w-[18%] h-full lg:flex items-center">
            <p className="font-[400]">{getDateTime(project?.updatedAt).date}</p>
            <p className="text-[11px] text-[#12121299] ml-2 truncate overflow-ellipsis pr-2">
              {getDateTime(project?.updatedAt).time}
            </p>
          </div>
          <button
            type="button"
            className="relative w-5 flex items-center justify-center cursor-auto"
            onClick={(e) => {
              e.stopPropagation();
              if (selectedOption === project?.id) {
                setSelectedOption("");
              } else {
                setSelectedOption(project?.id);
              }
            }}
            onBlur={() => setSelectedOption("")}
          >
            <Options />
            {selectedOption === project?.id && (
              <div className="absolute right-10 top-0 w-[200px] text-[13px] font-[200] text-[#717171] flex flex-col bg-white px-5 py-3 rounded-[8px] shadow-md">
                <div
                  onClick={() => {
                    activeProject &&
                      navigate(`/dedicated-nodes/${project?.id}`);
                  }}
                  className={`flex items-center gap-2 py-4 pl-5 ${
                    activeProject && "border-b-[0.5px] border-b-[#D8D8D8]"
                  } cursor-pointer`}
                >
                  <Open className="scale-90" />
                  <p>Open</p>
                </div>
                {activeProject && (
                  <div
                    onClick={() => {
                      if (liveProjectStatus === "active") {
                        setTerminateModalIsOpen(!terminateModalIsOpen);
                      }
                    }}
                    className={`flex items-center gap-2 py-3 pl-5 cursor-pointer ${
                      liveProjectStatus === "terminate" &&
                      "cursor-not-allowed opacity-70"
                    }`}
                  >
                    <Delete />
                    <p>Terminate</p>
                  </div>
                )}
              </div>
            )}
          </button>
        </div>
      )}
      <div>
        <TerminateModal
          terminateModalIsOpen={terminateModalIsOpen}
          setTerminateModalIsOpen={setTerminateModalIsOpen}
          project={{ id: project?.id }}
          projectType={"single-node"}
        />
      </div>
    </div>
  );
};

export default NodeRow;
