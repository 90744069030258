import axios from "../axios";

// Api Services endpoints
export const getApiActiveProjects = () => {
  return axios.get(`dashboard/apiServices`);
};

export const getApiTerminatedProjects = () => {
  return axios.get(`dashboard/apiService/terminated`);
};

export const getApiProject = (projectId) => {
  return axios.get(`dashboard/apiServices/${projectId}`);
};

export const deleteApiProject = (projectId, orgId) => {
  return axios.patch(`dashboard/apiservices/${projectId}`, {
    organizationId: orgId,
  });
};

export const changeApiKey = (projectId, payload) => {
  return axios.patch(`dashboard/projects/apiservices/${projectId}`, payload);
};

// Dedicated nodes endpoint
export const getDedicatedProjects = () => {
  return axios.get(`dashboard/dedicatedNode`);
};

export const getDedicatedProject = (projectId) => {
  return axios.get(`dashboard/dedicatedNode/${projectId}`);
};

export const terminateDedicatedProject = (projectId) => {
  return axios.patch(`dashboard/dedicatedNode/${projectId}`);
};

// Parachain nodes endpoints
export const getParachainProjects = () => {
  return axios.get(`dashboard/parachainNode`);
};

export const getParachainProject = (projectId) => {
  return axios.get(`dashboard/parachainNode/${projectId}`);
};

export const terminateParachainProject = (projectId, projectName) => {
  return axios.delete(
    `dashboard/parachainNode/${projectId}?projectName=${projectName}`
  );
};

export const getPendingParachainProjects = () => {
  return axios.get(`node/projectV2/pending`);
};
